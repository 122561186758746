import React, { Component } from "react";
import { Card, Button, Select } from "react-md";
import Title from "./Title";

import "../style/HomeView.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import BestellListe from "./BestellListe";
export default class Offerings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offerExtras: []
    };
  }

  componentDidMount(props) {
    var offerExtrasTemp = [];
    this.props.offer.extras.forEach(x => {
      offerExtrasTemp.push({ name: x.name, value: x.items[0].name });
    });
    this.setState(
      Object.assign({}, this.state, { offerExtras: offerExtrasTemp })
    );
  }

  render() {
    return (
      <div style={{ ...this.props.style, ...{ marginBottom: 20 } }}>
        <Card style={{ padding: 10, position: "relative" }}>
          <Button
            onClick={() => this.props.removeOffer()}
            icon
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              right: 5,
              top: 5,
              backgroundColor: "#FE7773"
            }}
            iconClassName="fa fa-times fa-lg"
          />

          <Title
            title={`Ausgewählt: ${this.props.offer.name}`}
            divider={true}
            facebook={false}
          ></Title>
          <div style={{ padding: 20 }}>
            <p>{this.props.offer.description}</p>
          </div>

          <BestellListe
            cart={this.props.offerCart}
            updateCart={this.props.updateCart}
            style={{ marginRight: 10, width: "calc(100% - 10px)" }}
            offer={this.props.offer}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 20
            }}
          >
            {this.props.offer.extras.map(x => (
              <Select
                inputStyle={{
                  color: "unset",
                  fontFamily: "'Dosis', sans-serif"
                }}
                simplifiedMenu={true}
                onChange={e => {
                  if (this.state.offerExtras.some(i => i.name === x.name)) {
                    this.state.offerExtras.find(
                      i => i.name === x.name
                    ).value = e;
                  } else {
                    this.state.offerExtras.push({ name: x.name, value: e });
                  }
                }}
                placeholder={x.name}
                menuItems={x.items.map(i => i.name)}
                position={Select.Positions.BELOW}
                defaultValue={x.items[0].name}
              />
            ))}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center"
            }}
          >
            {this.props.open ? (
              <div style={{display:"flex", flexDirection:"row", alignItems: "center", justifyContent:"space-around", width:"100%"}}>
                <Button
                  raised
                  style={{
                    padding: 10,
                    backgroundColor: "rgb(95, 173, 70)",
                    height: "unset"
                  }}
                  onClick={() => {
                    this.props.addOfferToCart(this.state.offerExtras);
                  }}
                >
                  Zum Warenkorb hinzufügen
                </Button>
                <h2
                  style={{ margin: 0, fontFamily: "'Dosis', serif" }}
                >{`${this.props.offer.price.toFixed(2)}€`}</h2>
              </div>
            ) : (
              <h2
                style={{ margin: 0, fontFamily: "'Dosis', serif" }}
              >{`Bitte zu den Öffnungszeiten bestellen.`}</h2>
            )}
          </div>
        </Card>
      </div>
    );
  }
}

const styles = {};
