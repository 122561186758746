import React from "react";
import { withRouter } from "react-router-dom";
import { Card, CardTitle, Button,TextField } from "react-md";

import UserService from "../../services/UserService"



class Login extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      username: "",
      password: ""
    };
  }

  login(user) {
    UserService.login(this.state.username, this.state.password).then((data) => {        
        this.props.onLogin()
    }).catch((e) => {
      this.props.onLoginFailed(e)
        this.setState({
            error: e
        });
    });
}

  render() {
    return (
      <Card style={{ ...styles.loginContainer }}>
        <CardTitle title={"Login"} />
        <TextField
          id="username"
          label="Username"
          lineDirection="center"
          placeholder="Bitte gebe deinen Benutzernamen ein"
          onChange={(txt) => this.setState(Object.assign({}, this.state, {username: txt}))}
        //   className="md-cell md-cell--bottom"
        />
        <TextField
          id="password"
          label="Bitte gebe ein Passwort ein."
          type="password"
          onChange={(txt) => this.setState(Object.assign({}, this.state, {password: txt}))}
        //   className="md-cell md-cell--bottom"
        />
        <Button onClick={() => this.login()}>Login</Button>
      </Card>
    );
  }
}

export default withRouter(Login);

const styles = {
  loginContainer: {
    minWidth: "300px",
    minHeight: "300px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 50,
  },
  loginBtn: {
      backgroundcolor: "green"
  }
};
