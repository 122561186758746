import React from "react";

import { Autocomplete, Card, Button, Badge } from "react-md";

import Sidebar from "../components/Sidebar";
import Footer from "../components/default/Footer";
import Content from "../components/default/Content";
import ContentContainer from "../components/default/ContentContainer";
import SidebarContainer from "../components/default/SidebarContainer";

import "../style/HomeView.css";
import Sidemenu from "../components/Sidemenu";

import OfferService from "../services/OfferService";
import FacebookSmall from "../components/FacebookSmall";

import Title from "../components/Title";
import { isMobile } from "react-device-detect";
import HttpService from "../services/HttpService";


import { Keyframes, Spring, animated, Easing } from "react-spring/renderprops";


import Header from "../components/default/Header";
import HeaderRight from "../components/default/HeaderRight";

import titleLogo from "../res/img/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

import VisibilitySensor from "react-visibility-sensor";

const config = require("../config/Config");

export class OfferView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeOffer: "-1",
      dishes: {},
      menu: [],
      offers: [],
      menuShown: false,
      showSidebar: false,
    };

    // this.filter = this.filter.bind(this);
    this.onClickMenuItem = this.onClickMenuItem.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.openSidebar = this.openSidebar.bind(this);
  }

  componentDidMount(props) {
    OfferService.getOffers().then((data) => {
      var state = this.state;
      state.offers = data;
      this.setState(state);
    });

    HttpService.getDeliveryTime().then((data) => {
      this.props.updateDeliveryTime(data.time);
    });

    HttpService.getTakeawayTime().then((data) => {
      this.props.updateTakeawayTime(data.time);
    });
  }

  openMenu() {
    this.setState(
      Object.assign({}, this.state, {
        // menuShown: !this.state.menuShown,
        showSidbarLeft: !this.state.showSidbarLeft,
        sidebarIsOpen: false,
      })
    );
  }

  openSidebar() {
    this.setState(
      Object.assign({}, this.state, {
        sidebarIsOpen: !this.state.sidebarIsOpen,
        showSidbarLeft: false,
      })
    );
  }
  openSidebar() {
    this.setState(
      Object.assign({}, this.state, { showSidebar: !this.state.showSidebar })
    );
  }

  addToCart(order, orderPrice) {
    if (this.state.activeOffer !== "-1") {
      // Add to offer Cart
    } else {
      var state = this.state;
      state.cart.orderPrice += orderPrice;
      state.cart.order.push(order);
      this.setState(state);
    }
  }

  // Neccessary
  onClickMenuItem(level, item) {
    this.props.history.push({
      pathname: item.path,
      state: { clicked: item.name },
    }); //this.props.location.pathname === path ? "": name}})

    if (item.name != undefined) {
      this.setState(
        Object.assign({}, this.state, { selectedCat: item.selection })
      );
    }
  }
  render() {
    return (
      <div
        className={"flexBoxColumn"}
        style={{ height: "100vh", justifyContent: "space-between" }}
      >
        <Header
          title={config.info.title}
          subTitle={config.info.subTitle}
          titleMobile="Roma"
          subTitleMobile=""
          titleLogo={titleLogo}
          openMenu={this.openMenu}
          socialMedia={false}
          openSidebar={this.openSidebar}
          cart={this.props.cart}
        >
          {/* Right of the SupTitle */}
          {isMobile && (
            <HeaderRight>
              <a
                href={config.info.subTitle.telAttr}
                style={{
                  display: "flex",
                  justifyContent: "cneter",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faPhone}
                  size="lg"
                  color="rgba(0,0,0,0.54)"
                />
              </a>
              {isMobile && this.openSidebar !== undefined && (
                <Button
                  icon
                  onClick={() =>
                    this.setState(
                      Object.assign({}, this.state, {
                        showSearch: !this.state.showSearch,
                      })
                    )
                  }
                  iconClassName="fa fa-search fa-2x"
                />
              )}
            </HeaderRight>
          )}
        </Header>

        {isMobile && (
          <div
            style={{
              position: "absolute",
              bottom: 20,
              right: 20,
              zIndex: 2000,
              width: "calc(100% - 40px)",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {!this.props.isOpen && (
              <div
                style={{
                  color: "white",
                  backgroundColor: "rgb(255, 58, 7)",
                  width: "100%",
                  padding: 10,
                  marginRight: 10,
                  fontSize: 14,
                  fontWeight: "bold"
                }}
              >
                Geschlossen <br/> 
                Öffnungszeiten: Di - So, 17:00 - 22:00
              </div>
            )}
            {/* <Badge
              badgeContent={this.props.cart.order.length}
              circular
              default
              badgeStyle={{
                color: "white",
                backgroundColor: "rgba(0,0,0,0.5)",
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              <Spring
                reset={this.state.pulseCartButton}
                immediate={!this.state.pulseCartButton}
                onRest={() => {
                  this.setState({ ...this.state, ...{ pulseCartButton: false } });
                }}
                from={{
                  transform: "scale(1.3)",
                  boxShadow: "0px 0px 85px 49px rgb(255, 58, 7)",
                }}
                to={{
                  transform: "scale(1)",
                  boxShadow: "0px 0px 0px 0px rgba(255, 58, 7, 0)",
                }}
              >
                {(props) => (
                  <animated.div style={{ ...props, ...{ borderRadius: "100%" } }}>
                    <Button
                      floating
                      style={{ backgroundColor: "rgb(255, 58, 7)" }}
                      onClick={() => this.openSidebar()}
                      iconClassName={
                        this.state.sidebarIsOpen
                          ? "fa fa-times fa-2x" //"fa fa-arrow-up fa-2x"
                          : "fa fa-shopping-cart fa-2x"
                      }
                    />
                  </animated.div>
                )}
              </Spring>
            </Badge> */}
          </div>
        )}

        <div
          style={{
            width: "100%",
            position: isMobile ? "" : "absolute",
            top: 75,
            zIndex: isMobile ? "" : 1010,
            position: "absolute",
          }}
        >
          <div
            style={{
              opacity: isMobile ? (this.state.showSearch ? "0.7" : "1") : "1",
              transition: " 2s",
              paddingLeft: 20,
              position: "absolute",
              right: isMobile ? undefined : 0,
              zIndex: 1011,
              display: isMobile ? "flex" : undefined,
              flexDirection: isMobile ? "row" : undefined,
            }}
          >
            <FacebookSmall />
          </div>
        </div>

        <ContentContainer id="content container" scrollable={true} mode={"footer"}>
          <SidebarContainer
            direction={"left"}
            sidebarIsOpen={this.state.showSidbarLeft}
            showMenu={this.openMenu}
            width={"20%"}
            fixed={true}
            from={"left"}
            background={true}
          >
            <Sidemenu
              allowedCategories={this.props.filter.allowedCategories}
              menu={config.menu}
              selected={
                this.props.filter.text.size > 0 ? "all" : this.state.selectedCat
              }
              onClickMenuItem={this.onClickMenuItem}
              setMenuWidth={this.setMenuWidth}
              overlap={true}
              showMenu={this.openMenu}
              style={{ marginTop: isMobile ? 10 : 100 }}
              clicked={
                this.props.history.location.state != undefined
                  ? this.props.history.location.state.clicked
                  : undefined
              }
            />
          </SidebarContainer>

          <Content
            id="content"
            width="50%"
            style={{
              display: "flex",
              alignItems: "baseline",
              flexWrap: "wrap",
              justifyContent: "center"
            }}
          >
            {this.state.offers.map((x) => (
              <Card
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  margin: isMobile ? "" : 10,
                  marginBottom: 20,
                  maxWidth: "350px",
                }}
              >
                <div style={{ padding: 20 }}>
                  <Title title={x.name} divider={true} />
                  <p>{x.description}</p>
                  {x.price && (
                    <h2 style={{ textAlign: "center", padding: 0 }}>
                      {x.price.toFixed(2) + "€"}
                    </h2>
                  )}
                </div>

                <Button
                  style={{
                    minHeight: 50,
                    width: "100%",
                    backgroundColor: "#028C6A",
                  }}
                  onClick={() => {
                    this.props.updateOffer(x);
                    this.props.history.push({
                      pathname: "/Home",
                    });
                  }}
                >
                  <h2 style={{ color: "white", padding: 10 }}>
                    Angebot auswählen
                  </h2>
                </Button>
              </Card>
            ))}
          </Content>

          <SidebarContainer
            direction={"right"}
            width={"30%"}
            background={true}
            sidebarIsOpen={this.state.sidebarIsOpen}
            from={"top"}
            style={{ zIndex: isMobile ? 1200 : undefined }}
          >
            <VisibilitySensor onChange={this.sidebarIsVisible}>
              {/* <Sidebar
                isOpen={this.props.isOpen}
                cart={this.state.cart}
                offer={this.props.offer}
                offerCart={this.props.offerCart}
                updateCart={this.props.updateCart}
                sidebarIsOpen={this.state.sidebarIsOpen}
                openSidebar={this.openSidebar}
                removeOffer={this.removeOffer}
                style={{ marginTop: isMobile ? 0 : 100 }}
                addOfferToCart={this.addOfferToCart}
                deliveryTime={this.props.deliveryTime}
                takeawayTime={this.props.takeawayTime}
              /> */}
            </VisibilitySensor>
          </SidebarContainer>

          <Footer />
        </ContentContainer>
      </div>
    );
  }
}

const styles = {
  footerStart: {},
  background: {},
  contentCard: {},
};
