import HttpService from './HttpService';
const config = require("../config/Config");


export default class FeedbackService {

    static baseURL() {return `${config.ip}/feedback` }

    static addFeedback(feedback){
       return new Promise((resolve, reject) => {
           HttpService.post(`${FeedbackService.baseURL()}/addfeedback`, feedback ,function(data) {
               resolve(data);
           }, function(textStatus) {
               reject(textStatus);
           });

       });
    }

}