import React from "react";
import { withRouter } from "react-router-dom";
import { Checkbox, Radio } from "@react-md/form";
import { FontIcon } from "@react-md/icon";
import { Dialog } from "@react-md/dialog";
import { Button } from "@react-md/button";
import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import { DialogTitle, Divider } from "react-md";

/**
 *
 */
class DishCardSelection extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            showDialog: false,
            counts: !(this.props.selected instanceof Array)
                ? [-1]
                : this.props.prices.map(
                      (x, i) =>
                          this.props.selected.filter((e) => (e.name === x.auswahl) & (e.preis === parseFloat(x.preis)))
                              .length
                  ),
        };

        this.shownText = this.shownText.bind(this);
        this.selectionItem = this.selectionItem.bind(this);
        this.onChange = this.onChange.bind(this);

        this.radioControls = this.props.prices.map((i, x) => {
            return {
                label: `${x.auswahl} ${parseFloat(x.preis).toFixed(2)}€`,
                value: i,
            };
        });

        if (this.props.title === "Extras auswählen.") {
            this.state.default = undefined;
        } else if (this.props.selected instanceof Array) {
            this.state.default = this.props.selected.map((x) => `${x.auswahl} ${x.preis.toFixed(2)}`);
        } else if (typeof this.props.selected === "object") {
            this.state.default = `${this.props.selected.auswahl} ${this.props.selected.preis.toFixed(2)}`;
        }
    }

    selectionItem = (auswahl, preis, i = 0) => {
        if (this.props.title === "Extras auswählen.") {
            return (
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div onClick={() => this.onChange(`${auswahl} ${parseFloat(preis).toFixed(2)}`)}>
                        {`${auswahl} ${parseFloat(preis).toFixed(2)}€`}
                    </div>
                    {this.state.counts.length > 1 && (
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div>{`${this.state.counts[i]}x`}</div>
                            {this.state.counts[i] > 0 && (
                                <Button
                                    icon
                                    style={{ color: "red" }}
                                    iconClassName="fa fa-minus"
                                    onClick={() =>
                                        this.onChange(`${auswahl} ${parseFloat(preis).toFixed(2)}`, "remove")
                                    }
                                />
                            )}
                        </div>
                    )}
                </div>
            );
        } else {
            return (
                <div onClick={() => this.onChange(`${auswahl} ${parseFloat(preis).toFixed(2)}`)}>
                    {`${auswahl} ${parseFloat(preis).toFixed(2)}€`}
                </div>
            );
        }
    };

    shownText() {
        if (this.props.text instanceof String || typeof this.props.text === "string") {
            return this.props.text;
        } else {
            return `${this.props.text.auswahl} ${this.props.text.preis.toFixed(2)}€`;
        }
    }

    onChange(selection, event = "add") {
        var idx = this.props.prices.findIndex((x) => x.auswahl == selection.split(" ")[0]);
        var state = this.state;
        if (event === "add" || event === "price") {
            event = this.props.title === "Extras auswählen." ? event : "price";
            state.counts[idx] += 1;
            this.setState(state);
            var last_idx = selection.split(" ").length - 1;
            this.props.changeSelection(
                selection.split(" ").splice(0, last_idx).join(" "),
                parseFloat(selection.split(" ")[last_idx]),
                event
            );
        } else if (event === "remove") {
            state.counts[idx] -= 1;
            this.setState(state);
            var last_idx = selection.split(" ").length - 1;
            // Requires index
            this.props.changeSelection(
                selection.split(" ").splice(0, last_idx).join(" "),
                parseFloat(selection.split(" ")[last_idx]),
                (event = "remove")
            );
        }
    }

    render() {
        return (
            <div style={{ ...this.props.style, ...{} }}>
                <Button
                    raised
                    disabled={this.props.disabled || this.props.prices.length < 1}
                    style={{ textTransform: "unset", height: "auto" }}
                    onClick={() =>
                        this.setState({
                            ...this.state,
                            ...{
                                showDialog: true,
                                counts: !(this.props.selected instanceof Array)
                                    ? [-1]
                                    : this.props.prices.map(
                                          (x, i) =>
                                              this.props.selected.filter(
                                                  (e) => (e.name === x.auswahl) & (e.preis === parseFloat(x.preis))
                                              ).length
                                      ),
                            },
                        })
                    }
                >
                    {this.shownText()}
                </Button>

                <Dialog
                    id="choosExtrasDialog"
                    aria-label="choosExtrasDialog"
                    focusOnMount={false}
                    visible={this.state.showDialog}
                    onRequestClose={() => this.setState({ ...this.state, ...{ showDialog: false } })}
                    style={{
                        zIndex: 11111,
                        width: isMobile ? "calc(100% - 10px)" : "",
                        // marginTop: 30,
                        maxHeight: isMobile ? "calc(100% - 70px)" : "70%",
                        padding: "0px 15px 0px 15px"
                    }}
                >
                    <h3>
                    {this.props.title}
                    </h3>
                    <div style={{overflowY: "auto", height: "100%"}}>
                    {this.props.prices.map((x, i) => {
                        if (x !== undefined) {
                            return this.props.singleSelection ? (
                                <Radio
                                    id={`radio-${i}`}
                                    name="radios"
                                    label={`${x.auswahl} ${parseFloat(x.preis).toFixed(2)}€`}
                                    onClick={() => this.onChange(`${x.auswahl} ${parseFloat(x.preis).toFixed(2)}`)}
                                />
                            ) : (
                                <Checkbox
                                    label={`${x.auswahl} ${parseFloat(x.preis).toFixed(2)}€`}
                                    defaultChecked={this.props.isItemChecked(x, this.props.text) ? true : undefined}
                                    onClick={() => this.onChange(`${x.auswahl} ${parseFloat(x.preis).toFixed(2)}`)}
                                />
                            );
                        }
                    })}
                    </div>

                    <Divider/>

                    <Button
                        raised
                        style={{
                            backgroundColor: "#69d171",
                            width: "80%",
                            marginLeft: "10%",
                            marginRight: "10%",
                            marginBottom: 20,
                            marginTop: 20
                        }}
                        onClick={() => this.setState({ ...this.state, ...{ showDialog: false } })}
                    >
                        Auswahl Bestätigen
                    </Button>
                </Dialog>
            </div>
        );
    }
}

/** TODO: WIP */
DishCardSelection.propTypes = {
    /** Prices is a list of names (e.g. ingredients) and its corresponding prices */
    prices: PropTypes.arrayOf(
        PropTypes.shape({
            auswahl: PropTypes.string,
            preis: PropTypes.number,
        })
    ),
};

export default withRouter(DishCardSelection);

const styles = {};
