import UserService from "./UserService";

const config = require("../config/Config");

export default class HttpService {
  constructor() {}

  static apiURL() {
    return config.ip;
  }

  static getDisplayWIP() {
    return new Promise((resolve, reject) => {
      HttpService.get(
        `${HttpService.apiURL()}/config/displaywippage`,
        function(data) {
          resolve(data);
        },
        function(textStatus) {
          console.log("Rejected")
          reject(textStatus);
        }
      );
    });
  }

  static getIsOpen() {
    return new Promise((resolve, reject) => {
      HttpService.get(
        `${HttpService.apiURL()}/config/isopen`,
        function(data) {
          resolve(data);
        },
        function(textStatus) {
          reject(textStatus);
        }
      );
    });
  }
  
  static getDeliveryTime() {
    return new Promise((resolve, reject) => {
      HttpService.get(
        `${HttpService.apiURL()}/config/deliverytime`,
        function(data) {
          resolve(data);
        },
        function(textStatus) {
          reject(textStatus);
        }
      );
    });
  }

  static getTakeawayTime() {
    return new Promise((resolve, reject) => {
      HttpService.get(
        `${HttpService.apiURL()}/config/takeawaytime`,
        function(data) {
          resolve(data);
        },
        function(textStatus) {
          reject(textStatus);
        }
      );
    });
  }

  static getFile(url, onSuccess, onError) {
    let token = window.localStorage["jwtToken"];
    let header = new Headers();
    if (token) {
      header.append("Authorization", `JWT ${token}`);
    }

    fetch(url, {
      method: "GET",
      headers: header
    })
      .then(resp => {
        return this.checkIfUnauthorized(resp, false);
      })
      .then(resp => {
        if (!resp) {
          onError("Logged out due to session expiration!");
        } else if (resp.error) {
          onError(resp.error);
        } else {
          if (resp.hasOwnProperty("token")) {
            window.localStorage["jwtToken"] = resp.token;
          }
          onSuccess(resp);
        }
      })
      .catch(e => {
        if (e.message) {
          return onError(e.message);
        } else {
          return onError("Something went wrong!");
        }
      });
    }

  static get(url, onSuccess, onError) {
    let token = window.localStorage["jwtToken"];
    let header = new Headers();
    if (token) {
      header.append("Authorization", `JWT ${token}`);
    }

    fetch(url, {
      method: "GET",
      headers: header
    })
      .then(resp => {        
        return this.checkIfUnauthorized(resp, true);
      })
      .then(resp => {
        if (!resp) {
          onError("Logged out due to session expiration!");
        } else if (resp.error) {
          onError(resp.error);
        } else {
          if (resp.hasOwnProperty("token")) {
            window.localStorage["jwtToken"] = resp.token;
          }
          
          onSuccess(resp);
        }
      })
      .catch(e => {
        if (e.message) {
          return onError(e.message);
        } else {
          return onError("Something went wrong!");
        }
      });
  }

  static put(url, data, onSuccess, onError) {
    let token = window.localStorage["jwtToken"];
    let header = new Headers();
    if (token) {
      header.append("Authorization", `JWT ${token}`);
    }
    header.append("Content-Type", "application/json");

    fetch(url, {
      method: "PUT",
      headers: header,
      body: JSON.stringify(data)
    })
      .then(resp => {        
        return this.checkIfUnauthorized(resp, true);
      })
      .then(resp => {
        if (!resp) {
          onError("Logged out due to session expiration!");
        } else if (resp.error) {
          onError(resp.error);
        } else {
          if (resp.hasOwnProperty("token")) {
            window.localStorage["jwtToken"] = resp.token;
          }
          onSuccess(resp);
        }
      })
      .catch(e => {
        if (e.message) {
          return onError(e.message);
        } else {
          return onError("Something went wrong!");
        }
      });
  }

  static post(url, data, onSuccess, onError) {
    let token = window.localStorage["jwtToken"];
    let header = new Headers();
    if (token) {
      header.append("Authorization", `JWT ${token}`);
    }
    header.append("Content-Type", "application/json");

    fetch(url, {
      method: "POST",
      headers: header,
      body: JSON.stringify(data)
    })
      .then(resp => {
        return this.checkIfUnauthorized(resp, true);
      })
      .then(resp => {
        if (!resp) {
          onError("Logged out due to session expiration!");
        } else if (resp.error) {
          onError(resp.error);
        } else {
          if (resp.hasOwnProperty("token")) {
            window.localStorage["jwtToken"] = resp.token;
          }
          onSuccess(resp);
        }
      })
      .catch(e => {
        if (e.message) {
          return onError(e.message);
        } else {
          return onError("Something went wrong!");
        }
      });
  }

  static remove(url, onSuccess, onError) {
    let token = window.localStorage["jwtToken"];
    let header = new Headers();
    if (token) {
      header.append("Authorization", `JWT ${token}`);
    }

    fetch(url, {
      method: "DELETE",
      headers: header
    })
      .then(resp => {


        return this.checkIfUnauthorized(resp, true);
      })
      .then(resp => {
        if (!resp) {
          onError("Logged out due to session expiration!");
        } else if (resp.error) {
          onError(resp.error);
        } else {

          console.log('fetch')

          onSuccess(resp);
        }
      })
      .catch(e => {
        if (e.message) {
          return onError(e.message);
        } else {
          return onError("Something went wrong!");
        }
      });
  }

  static checkIfUnauthorized(resp, isJson) {
    if (resp.status === 401) {
      UserService.logout();
      // window.location = "/#login";
      return;
    } else {      
      return isJson ? resp.json() : resp
      
    }
  }
}
