import HttpService from './HttpService';
const config = require("../config/Config");


export default class OrderService {

    static baseURL() {return `${config.ip}/o` }

    static addOrder(order){
       return new Promise((resolve, reject) => {
           HttpService.post(`${OrderService.baseURL()}/addorder`, order ,function(data) {
               resolve(data);
           }, function(textStatus) {
               reject(textStatus);
           });

       });
    }

}