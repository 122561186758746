import HttpService from './HttpService';
const config = require("../config/Config");


export default class OfferService {

    static baseURL() {return `${config.ip}/offers` }

    static getOffers(){
       return new Promise((resolve, reject) => {
           HttpService.get(`${OfferService.baseURL()}/getoffers`, function(data) {
               resolve(data);
           }, function(textStatus) {
               reject(textStatus);
           });
       });
    }

}