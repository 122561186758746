import React from "react";
import { Dialog } from "@react-md/dialog";

// import { useSpring, animated, interpolate } from "react-spring";

import { isMobile } from "react-device-detect";


export default class SidebarContainer extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      backgroundAnimDone: true,
      contentAnimDone: true,
    };

    this.container = this.container.bind(this);
    this.content = this.content.bind(this);
    this.background = this.background.bind(this);
  }

  componentWillReceiveProps() {}

  content() {
    return isMobile
      ? {
          display: isMobile && this.props.sidebarIsOpen ? undefined : "none",
          position: "absolute",
          overflow: "hidden",
          left: this.props.from === "top" ? 10 : 0,
          right: this.props.from === "top" ? 10 : 0,
          width: "calc(100vw - 20px)",
          overflowY: "auto",
        }
      : { height: "unset" };
  }

  container() {
    if (isMobile) {
      return {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "calc(100% - 56px)",
        width: "100vw",
        display: this.props.sidebarIsOpen && this.state.backgroundAnimDone && this.state.contentAnimDone ? "" : "none",
        position: "absolute",
        // top:  isTablet ? "60px" :"56px",
        // zIndex: 1400,
      };
    } else {
      return {
        width: this.props.width,
        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "flex-start",
      };
    }
  }

  background() {
    return {
      position: isMobile ? "absolute" : "",
      height: isMobile ? "100vh" : undefined,
      width: isMobile ? "100vw" : undefined,
      backgroundColor: this.props.background ? "rgba(0,0,0,0.4)" : "rgba(0,0,0,0.0)",
    };
  }

  render() {
    return (
      <div style={this.container()}>
    
        {isMobile ? (
          <Dialog
          type="full-page"
            visible={this.props.sidebarIsOpen}
            onRequestClose={() => this.setState({ ...this.state, ...{ sidebarIsOpen: false } })}
            style={{ marginTop: "56px", overflowY: "auto"}}
            visible={this.props.sidebarIsOpen}
          >
            <div>{this.props.children}</div>
          </Dialog>
        ) : (
          <div>{this.props.children}</div>
        )}
      </div>
    );
  }
}

const styles = {
  //   container: {
  //     display: "flex",
  //     flexDirection: "column",
  //     justifyContent: "flex-start",
  //     height: "100%",
  //     width: isMobile  ? "50%" : "30%",
  //     backgroundColor: isMobile  ? "white" : "",
  //     position: isMobile  ? "absolute" : "",
  //     top: isMobile  ? "56px" : "",
  //     // right: isMobile  ? "-50%" : "",
  //     transition: "right 1s"
  //   }
};
