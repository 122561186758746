"use strict";

import React from "react";

import Title from "../components/Title";
import {
    Card,
  CardContent,
} from "react-md";

import { Button } from "@react-md/button";

import background from "../res/img/title_background.png";

import "../style/StartView.css";
import Subtitle from "../components/Subtitle";
import FacebookLarge from "../components/FacebookLarge";
import FacebookSmall from "../components/FacebookSmall";

import { isMobile } from "react-device-detect";

import titleLogo from "../res/img/logo.svg";
import Header from "../components/default/Header";
import HeaderRight from "../components/default/HeaderRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faPhone
} from "@fortawesome/free-solid-svg-icons";


const config = require("../config/Config");

export class StartView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: ""
    };
  }

  render() {
    return (
      <div>
        <Header
          title={config.info.title}
          subTitle={config.info.subTitle}
          titleMobile="Roma"
          subTitleMobile=""
          titleLogo={titleLogo}
          // openMenu={this.openMenu}
          socialMedia={false}
          openSidebar={this.openSidebar}
          cart={this.props.cart}
          >
          {/* Right of the SupTitle */}
          {isMobile && (
          <HeaderRight>            
              <a
                href={config.info.subTitle.telAttr}
                style={{
                  display: "flex",
                  justifyContent: "cneter",
                  alignItems: "center"
                }}
              >
                <FontAwesomeIcon
                  icon={faPhone}
                  size="lg"
                  color="rgba(0,0,0,0.54)"
                />
              </a>  
          </HeaderRight>
          )}
        </Header>

        <div
          style={{
            overflow: "hidden",
            height: "100vh",
            backgroundImage: isMobile ? undefined :`url(${background})`,
            backgroundSize: "cover"
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              // backgroundColor: "rgba(200,200,200,0.5)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
               alignItems: "center"
            }}
          >
          <div style={{width: isMobile ? "" :"95%"}}>
            <FacebookSmall style={{marginTop: "20px",}} />
          </div>
          

            <Card
              style={{
                
                width: "95%",
                maxHeight: "calc(90% - 60px)",
                backgroundColor: "rgba(255,255,255,0.9)",
                overflow: "auto", 
              }}
            >
              <Title title="Willkommen bei Pizzeria Roma" divider={true} facebook={true}/>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                flexFlow: "row wrap"
                }}
              >
                
                <div style={{width: isMobile? "100%" : "50%"}}>
                  <CardContent style={{ padding: "0px 25px 15px 25px " }}>
                  <Button
                  themeType="contained"
                  onClick={() => this.props.history.push("/Home")}
                    style={{
                      width: "calc(100% - 50px)",
                      margin: 25,
                      padding: 5,
                      backgroundColor: "#21ba45"
                    }}
                  >
                    <h2
                      style={{
                        margin: 0,
                        fontFamily: "'Dosis', sans-serfi",
                        color: "white"
                      }}
                    >
                      Zu den Gerichten
                    </h2>
                  </Button>
                    <p>
                      mit unserer neuen Webseite möchten wir Ihnen einen
                      weiteren einfachen sowie zeitgemäßen Weg ermöglichen Ihre
                      Lieblingsgerichte bei uns zu bestellen. Über den grünen
                      Button auf der linknen Seite kommen Sie direkt zu unserer
                      Auswahl an Pizzen, Nudeln, Salaten und vieles mehr!
                    </p>
                    <p>
                      Natürlich können Sie uns weiterhin auch über Telefon
                      erreichen. Wir freuen uns auch Sie persönlich bei uns in
                      der Pizzeria begrüßen zu dürfen. Wenn Sie gerne Ihr essen
                      in entspannter atmosphäre genießen möchten, finden Sie uns
                      unter folgender Adressezu den angegebenen Öffnungszeiten:
                    </p>
                  </CardContent>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexDirection: "row",
                      flexWrap: "wrap"
                    }}
                  >
                    <Card style={{ display: "inline-block", marginTop: "7px" , width: "95%" }}>
                      <Subtitle title="Öffnungszeiten" divider={true} />
                      <CardContent style={{ padding: "0px 60px 15px 60px"}}>
                        <p>Dienstag bis Sonntag (inkl. Feiertags):</p>
                        <p>
                          <b>17:00 Uhr - 22:30 Uhr</b>
                        </p>
                        <p>
                          Lieferzeit: <b>17:00 Uhr - 22:00 Uhr</b>
                        </p>
                        <p>
                          <b>Montag Ruhetag!</b>
                        </p>
                      </CardContent>
                    </Card>

                    <Card style={{ display: "inline-block" , marginTop: "7px" , width: "95%" }}>
                      <Subtitle title="Liefergebiete" divider={true} />
                      <CardContent style={{ padding: "0px 25px 15px 25px"}}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            flexDirection: "row",
                            marginBottom: "25px",
                            flexWrap: "wrap"
                          }}
                        >
                          <div style={{ margin: 5 }}>
                            <p>
                              <b>Marl</b>
                            </p>
                            <p>Polsum</p>
                            <p>Alt-Marl</p>
                          </div>

                          <div style={{ margin: 5 }}>
                            <p>
                              <b>Dorsten</b>
                            </p>
                            <p>Altendorf</p>
                          </div>

                          <div style={{ margin: 5 }}>
                            <p>
                              <b>Gelsenkirchen</b>
                            </p>
                            <p>Hassel</p>
                          </div>

                          <div style={{ margin: 5 }}>
                            <p>
                              <b>Herten</b>
                            </p>
                            <p>Bertlich</p>
                            <p>Westerholt</p>
                          </div>


                        </div>
                      </CardContent>
                    </Card>
                  </div>
                  
                </div>

                <FacebookLarge style={{marginLeft: "2.5%",marginTop: 25, width: isMobile ? "100%" :  "45%"}} />

              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  footerStart: {
    minHeight: "120px"
  },
  background: {
    width: "100%",
    height: "calc(100% - 80px)"
  },
  contentCard: {}
};
