import React, { Component } from "react";

export default class FacebookSmall extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ ...this.props.style }}>
        <div
          className="fb-like fb_iframe_widget"
          data-href="https://www.facebook.com/Pizzeria-Roma-Marl-Polsum-120282051363696/?ref=br_rs"
          data-layout="button_count"
          data-action="like"
          data-size="large"
          data-show-faces="true"
          data-share="true"
          style={{color: "white"}}
          fb-xfbml-state="rendered"
          fb-iframe-plugin-query="action=like&amp;app_id=&amp;container_width=1443&amp;href=https%3A%2F%2Fwww.facebook.com%2FPizzeria-Roma-Marl-Polsum-120282051363696%2F%3Fref%3Dbr_rs&amp;layout=button_count&amp;locale=de_DE&amp;sdk=joey&amp;share=true&amp;show_faces=true&amp;size=large"
        >
          <span style={{verticalAlign: "bottom", width: "200px", height: "30px"}}>
            <iframe
              name="f18bcfaee9a95ac"
              width="1000px"
              height="1000px"
              // frameborder="0"
              allowtransparency="true"
              allowFullScreen={true}
              scrolling="no"
              allow="encrypted-media"
              title="fb:like Facebook Social Plugin"
              src="https://www.facebook.com/v3.2/plugins/like.php?action=like&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fconnect%2Fxd_arbiter%2Fr%2Fd_vbiawPdxB.js%3Fversion%3D44%23cb%3Df34e3f2a39ded88%26domain%3Dpizzeriaroma-polsum.de%26origin%3Dhttps%253A%252F%252Fpizzeriaroma-polsum.de%252Ff12119ca8d06f34%26relation%3Dparent.parent&amp;container_width=1443&amp;href=https%3A%2F%2Fwww.facebook.com%2FPizzeria-Roma-Marl-Polsum-120282051363696%2F%3Fref%3Dbr_rs&amp;layout=button_count&amp;locale=de_DE&amp;sdk=joey&amp;share=true&amp;show_faces=true&amp;size=large"
              style={{border: "none", visibility: "visible", width: "200px", height: "30px"}}
              className=""
            />
          </span>
        </div>
      </div>
    );
  }
}

const styles = {
  menuHeadContainer: {}
};
