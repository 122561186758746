import React from "react";

import Sidebar from "../components/Sidebar";
import Footer from "../components/default/Footer";
import Content from "../components/default/Content";
import ContentContainer from "../components/default/ContentContainer";
import SidebarContainer from "../components/default/SidebarContainer";

import "../style/HomeView.css";
import Sidemenu from "../components/Sidemenu";

import FacebookSmall from "../components/FacebookSmall";

import { isMobile } from "react-device-detect";
import HttpService from "../services/HttpService";
import Title from "../components/Title";

import Header from "../components/default/Header";

import titleLogo from "../res/img/logo.svg";

const config = require("../config/Config");

export class ImpressumView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onClickMenuItem = this.onClickMenuItem.bind(this);
    this.openMenu = this.openMenu.bind(this);
  }

  componentDidMount(props) {
    setInterval(() => {
      HttpService.getDeliveryTime().then(data => {
        this.props.updateDeliveryTime(data.time);
      });

      HttpService.getTakeawayTime().then(data => {
        this.props.updateTakeawayTime(data.time);
      });
    }, 15000);

    if (this.props.location.state !== undefined) {
      this.props.updateOffer(this.props.location.state.offer);
    }
  }

  onClickMenuItem(level, item) {
    this.props.history.push({
      pathname: item.path,
      state: { clicked: item.name }
    }); //this.props.location.pathname === path ? "": name}})

    if (item.name !== undefined) {
      this.setState(
        Object.assign({}, this.state, { selectedCat: item.selection })
      );
    }
  }

  openMenu() {
    this.setState(
      Object.assign({}, this.state, {
        // menuShown: !this.state.menuShown,
        showSidbarLeft: !this.state.showSidbarLeft
      })
    );
  }

  render() {
    return (
      <div
        className={"flexBoxColumn"}
        style={{ height: "100vh", justifyContent: "space-between" }}
      >
        <Header
          title={config.info.title}
          subTitle={config.info.subTitle}
          titleMobile="Roma"
          subTitleMobile=""
          titleLogo={titleLogo}
          openMenu={this.openMenu}
          socialMedia={false}
          openSidebar={this.openSidebar}
          cart={this.props.cart}
        >
        </Header>

        <div
          style={{
            width: "100%",
            position: isMobile ? "" : "absolute",
            top: 75,
            zIndex: isMobile ? "" : 1010,
            position: "absolute",
          }}
        >
          <div
            style={{
              opacity: isMobile ? (this.state.showSearch ? "0.7" : "1") : "1",
              transition: " 2s",
              paddingLeft: 20,
              position: "absolute",
              right: isMobile ? undefined : 0,
              zIndex: 1011,
              display: isMobile ? "flex" : undefined,
              flexDirection: isMobile ? "row" : undefined,
            }}
          >
            <FacebookSmall />
          </div>
        </div>

        <ContentContainer scrollable={true} mode={"footer"}>
        <SidebarContainer
            direction={"left"}
            sidebarIsOpen={this.state.showSidbarLeft}
            showMenu={this.openMenu}
            width={"20%"}
            fixed={true}
            from={"left"}
            background={true}
          >
            <Sidemenu
              // allowedCategories={this.state.filter.allowedCategories}
              menu={config.menu}
              selected={"Impressum"}
              onClickMenuItem={this.onClickMenuItem}
              setMenuWidth={this.setMenuWidth}
              overlap={true}
              showMenu={this.openMenu}
              style={{ marginTop: isMobile ? 10 : 100 }}
              clicked={this.props.history.location.state !== undefined ? this.props.history.location.state.clicked : undefined}
            />
          </SidebarContainer>

          <Content width="40%">   
            <Title title={"Impressum"} divider={true} />
            <p>Developed in Polsum.</p>
            <Title title={"Materialien"} divider={true} />
            <p>
              Einige der auf dieser Seite verwendeten Materialien (Icons, Bilder
              etc.) dürfen auf dieser Seite verwendet werden, unter der
              Bedingung, dass die Autoren/Ersteller/Designer entsprechend
              verlinkt sind. Daher finden Sie im Folgenden sämtliche
              Autoren/Ersteller/Designer die Ihre Arbeit für unsere Zwecke zur
              Verfügung gestellt haben.
            </p>
            <div>
              <div>
                Icons made by{" "}
                <a
                  href="https://www.flaticon.com/authors/smashicons"
                  title="Smashicons"
                >
                  Smashicons
                </a>{" "}
                from{" "}
                <a href="https://www.flaticon.com/" title="Flaticon">
                  www.flaticon.com
                </a>
              </div>
              <div>
                Icons made by{" "}
                <a
                  href="https://www.flaticon.com/authors/pixel-perfect"
                  title="Pixel perfect"
                >
                  Pixel perfect
                </a>{" "}
                from{" "}
                <a href="https://www.flaticon.com/" title="Flaticon">
                  www.flaticon.com
                </a>
              </div>
              <div>
                Icons made by{" "}
                <a
                  href="https://www.flaticon.com/authors/twitter"
                  title="Twitter"
                >
                  Twitter
                </a>{" "}
                from{" "}
                <a href="https://www.flaticon.com/" title="Flaticon">
                  www.flaticon.com
                </a>
              </div>
              <div>
                Icons made by{" "}
                <a
                  href="https://www.flaticon.com/authors/google"
                  title="Google"
                >
                  Google
                </a>{" "}
                from{" "}
                <a href="https://www.flaticon.com/" title="Flaticon">
                  www.flaticon.com
                </a>
              </div>
              <div>
                Icons made by{" "}
                <a
                  href="https://www.flaticon.com/authors/pause08"
                  title="Pause08"
                >
                  Pause08
                </a>{" "}
                from{" "}
                <a href="https://www.flaticon.com/" title="Flaticon">
                  www.flaticon.com
                </a>
              </div>
              <div>
                Icons made by{" "}
                <a
                  href="https://www.flaticon.com/authors/photo3idea-studio"
                  title="photo3idea_studio"
                >
                  photo3idea_studio
                </a>{" "}
                from{" "}
                <a href="https://www.flaticon.com/" title="Flaticon">
                  www.flaticon.com
                </a>
              </div>
              <div>
                Icons made by{" "}
                <a
                  href="https://www.flaticon.com/authors/freepik"
                  title="Freepik"
                >
                  Freepik
                </a>{" "}
                from{" "}
                <a href="https://www.flaticon.com/" title="Flaticon">
                  www.flaticon.com
                </a>
              </div>
              <div>
                Icons made by{" "}
                <a
                  href="https://www.flaticon.com/authors/smashicons"
                  title="Smashicons"
                >
                  Smashicons
                </a>{" "}
                from{" "}
                <a href="https://www.flaticon.com/" title="Flaticon">
                  www.flaticon.com
                </a>
              </div>
              
            </div>
            <Title title={"Version"} divider={true} />
            <p>v 0.0.7</p>
          </Content>

          <SidebarContainer
            direction={"right"}
            width={"30%"}
            // fixed={true}
            showSidebar={this.state.showSidebar}
            from={"top"}
          >
            <Sidebar
            isOpen={this.props.isOpen}
              cart={this.props.cart}
              offer={this.props.offer}
              offerCart={this.props.offerCart}
              updateCart={this.props.updateCart}
              showSidebar={this.state.showSidebar}
              openSidebar={this.openSidebar}
              removeOffer={this.removeOffer}
              style={{ marginTop: isMobile ? 0 : 100 }}
              addOfferToCart={this.addOfferToCart}
              deliveryTime={this.props.deliveryTime}
              takeawayTime={this.props.takeawayTime}
            />
          </SidebarContainer>
          <Footer />
        </ContentContainer>
      </div>
    );
  }
}

const styles = {
  footerStart: {
    minHeight: "120px"
  },
  background: {},
  contentCard: {}
};
