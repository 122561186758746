import React from "react";

import { withRouter } from "react-router-dom";
import { List, ListItem } from "@react-md/list";
import { DeleteForeverSVGIcon } from "@react-md/material-icons";
import { Button } from "@react-md/button";

import rubbish from "../res/img/rubbish-bin.svg";

class BestellListe extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      cart: this.props.cart
    };
  }

  render() {
    return (
      <List
        style={{ ...this.props.style }}
        className="md-cell md-paper md-paper--1"
      >
        {this.props.cart.order.map((c, i) => (
          <ListItem
          leftAddon={
              <Button buttonType="icon" onClick={() => {
                var state = this.state;
                state.cart.order.splice(i, 1);
                state.cart.orderPrice -= c.price;
                this.props.updateCart(state.cart);
                this.setState(state);
              }}>
                  <DeleteForeverSVGIcon />
              </Button>
          }
      >
          <div>
                {this.props.offer === undefined &&
                  c.offer !== "-1" && (
                    <div style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}>
                      <div style={{ fontWeight: "bold" }}>{c.offer}</div>
                      <div>{parseFloat(c.price).toFixed(2) + "€"} </div>
                    </div>
                    
                  )}
                {c.dishes.map((d, k) => (
                  <div key={{k}}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}
                    >
                      <div style={{display: "flex", justifyContent:"space-between", flexWrap: "wrap", flexGrow: "1", paddingRight: 5}}>
                        <div>{d.nr !== "" ? "Nr." + d.nr + " " + d.name : d.name}</div>
                        <div>{d.selection}</div>
                      </div>
                      <div>{parseFloat(d.price).toFixed(2) + "€"}</div>
                    </div>

                    {this.props.offer === undefined &&
                      c.offer !== "-1" && (
                        <div style={{ color: "rgba(0,0,0,0.54)" }}>
                          {d.minus != undefined ? (
                            d.minus.map((x, j) => (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  flexWrap: "wrap"
                                }}
                              >
                                <div>- {x.name}</div>
                                <div>{x.preis.toFixed(2) + "€"}</div>
                              </div>
                            ))
                          ) : (
                            <div />
                          )}
                          {d.additionalSelection != undefined ? (
                            Object.entries(d.additionalSelection).map(([key, value], j) => (
                              <div
                              key={j}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  flexWrap: "wrap"
                                }}
                              >
                                <div>{key}</div>
                                <div>{value.name}</div>
                              </div>
                            ))
                          ) : (
                            <div />
                          )}

                          {d.extras != undefined ? (
                            d.extras.map((x, j) => (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  flexWrap: "wrap"
                                }}
                              >
                                <div>+ {x.name}</div>
                                <div>{x.preis.toFixed(2) + "€"}</div>
                              </div>
                            ))
                          ) : (
                            <div />
                          )}
                        </div>
                      )}
                      {d.notice != ""&&
                      <div><em>"{d.notice}"</em></div>}
                  </div>
                ))}
              </div>

              {this.props.offer === undefined && c.offer !== "-1" ? (
                <div>
                  {c.offerExtras.map(x => (
                    <div>{x.name + ": " + x.value}</div>
                  ))}
                </div>
              ) : (
                c.dishes.map((d, k) => (
                  <div key={{k}}>
                    {d.additionalSelection != undefined ? (
                            Object.entries(d.additionalSelection).map(([key, value], j) => (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  flexWrap: "wrap"
                                }}
                              >
                                <div>{key}</div>
                                <div>{value.name}</div>
                              </div>
                            ))
                          ) : (
                            <div />
                          )}
                          
                    {d.minus != undefined ? (
                      d.minus.map((x, j) => (
                        <div
                          key={j}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap"
                          }}
                        >
                          <div>- {x.name}</div>
                          <div>{x.preis.toFixed(2) + "€"}</div>
                        </div>
                      ))
                    ) : (
                      <div />
                    )}

                    {d.extras != undefined ? (
                      d.extras.map((x, j) => (
                        <div
                          key={j}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap"
                          }}
                        >
                          <div>+ {x.name}</div>
                          <div>{x.preis.toFixed(2) + "€"}</div>
                        </div>
                      ))
                    ) : (
                      <div />
                    )}
                  </div>
                )))}
      </ListItem>

        ))}
      </List>
    );
  }
}

export default withRouter(BestellListe);

const styles = {};
