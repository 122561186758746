import React, { Component } from "react";
import { Divider } from "react-md";

export default class Subtitle extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
      }

  render() {
    return (
        <div style={{...this.props.style}}>

          <div style={{...styles.menuHeadContainer,...this.props.containerStyle}}>
            {this.props.icon !== undefined &&
            <img
              src={this.props.icon}
              style={{ ...{ width: 30, height: "auto" }, ...this.props.iconStyle}}
              alt="Logo"
            />
            }

            <h3 style={{...{ fontFamily: "'Dosis', serif", fontWeight: "600",margin: 0, whiteSpace: "normal"}, ...this.props.textStyle}}>
              {this.props.title}
            </h3>

            {this.props.date &&
            <h4 style={{...{ fontFamily: "'Dosis', serif", fontWeight: "600", whiteSpace: "normal" }, ...this.props.textStyle}}>{this.props.date}</h4>
            }

            
          </div>

          {this.props.divider &&
                <Divider style={{marginBottom: 20,marginTop: 15}} inset />  
            }
      </div>
    );
  }
}


const styles = {
    menuHeadContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingLeft: "30px",
      paddingRight: "30px",
      paddingTop: "10px",
    }
  };
  