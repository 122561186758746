import React from "react";
import { isBrowser, isMobile } from "react-device-detect";

import Title from "../components/Title";
import {} from "react-md";

import background from "../res/img/title_background.png";
import technicalSupport from "../res/img/icons/technical-support.svg";

import "../style/StartView.css";
import FacebookSmall from "../components/FacebookSmall";

import ContentContainer from "../components/default/ContentContainer";
import Content from "../components/default/Content";
import Footer from "../components/default/Footer";
import Header from "../components/default/Header";
import titleLogo from "../res/img/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone
} from "@fortawesome/free-solid-svg-icons";


const config = require("../config/Config");

export class WorkingView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: ""
    };
  }

  render() {
    return (
      <div>
        <Header
          title={config.info.title}
          subTitle={config.info.subTitle}
          titleMobile="Roma"
          subTitleMobile=""
          titleLogo={titleLogo}
          socialMedia={false}
        >
        </Header>

        <div
          style={{
            overflow: "hidden",
            height: "100vh"
          }}
        >
          <img
            src={technicalSupport}
            style={{
              position: "absolute",
              zIndex: -1,
              width: 300,
              height: 300,
              left: 0,
              right: 0,
              top: "40%",
              marginLeft: "auto",
              marginRight: "auto"
            }}
          />
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255,255,255,0.8)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center"
            }}
          >
            <div style={{ width: isMobile ? "" : "95%" }}>
              <FacebookSmall style={{ marginTop: "20px" }} />
            </div>

            <ContentContainer
              scrollable={true}
              style={{ 
                  justifyContent: "center",
                //   alignItems: "center"
             }}
              mode={"footer"}
            >
              <Content
                style={{
                width: isBrowser ? "60%" : "",
                height: "calc(100% - 150px)",
                  display: "flex",
                  flexDirection: "column",
                  //   justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Title
                  fontStyle={{ fontSize: 32, textAlign: "left" }}
                  divider={true}
                  title={"Pizzeria Roma Polsum ist vorübergehend offline."}
                />

                <p>
                  Es wird zur Zeit an einer neuen Version der Pizzeria Roma
                  Webseite gearbeitet. Wir haben diverses Feedback bekommen und
                  möchten das verwenden, um die Onlinebestellung für Sie in
                  Zukunft besser zu gestalten. Dafür müssen wir für eine nicht
                  all zu lange Zeit die aktuelle Version abschalten. Zum aktuellen Stand gehen wir von einem Web-Re-Opening im November aus.
                  <br /> <br />
                  Wir sind aber natürlich weiterhin für
                </p>

                <Title
                  fontStyle={{ fontSize: 26, textAlign: "left" }}
                  title={"vor Ort, in der Kolpingstr. 2 in Polsum"}
                />

                <p>und</p>

                <Title
                  fontStyle={{ fontSize: 26, textAlign: "left" }}
                  title={"per Telefon unter "}
                />

                <h2
                  style={{
                    ...{
                      fontFamily: "'Dosis', serif",
                      fontWeight: "700",
                      marginTop: 20,
                      marginBottom: 40,
                      fontSize: 40,
                    },
                  }}
                >
                  <a href={config.info.subTitle.telAttr}>
                    {config.info.subTitle.tel}
                  </a>
                </h2>

                <p style={{width: "100%"}}>
                  Außerdem finden Sie uns weiterhin in Facebook und bei Google.
                </p>



              </Content>
                  
            <Footer/>

            </ContentContainer>
          </div>
        </div>
      </div>
    );
  }
}
