import React from "react";
import {} from "react-md";

import { isBrowser, isMobile } from "react-device-detect";

export default class Content extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    return (
      <div
        style={{
          ...styles.container,          
          ...{
            overflow: this.props.scrollable ? "auto" : "",
            width: isMobile ? "calc(100% - 20px)" : this.props.width,
            margin: isMobile? "10px": "",
            marginBottom: "20px"
          },
          ...this.props.style,
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

const styles = {
  container: {
    width: isBrowser ? "40%" : "90%",
    height: "100%", // "calc(100vh - 64px)",
    textAlign: "justify",
    // zIndex: isMobile ? "" : 1000,
    paddingTop: isMobile ? 40 : 10
    // display: "flex",
    // justifyContent: "center",
    // // flexDirection: "column",
    // alignItems: "base"
  }
};
