import React from "react";
import { isMobile } from "react-device-detect";

import Scroll from 'react-scroll';
const Element = Scroll.Element;
const scroller = Scroll.scroller


export default class ContentContainer extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    return (
      <Element
        id={this.props.id}
        style={{
          ...styles.container,          
          ...{
            // marginBottom: this.props.mode === "footer" ? "0%" :"20px",
            // height:this.props.mode === "footer" ? "100%" :"calc(100vh - 100px)",
            // overflow: this.props.scrollable ? "auto" : "",
            // overflowX: isMobile ? "hidden" : "",
            // justifyContent: React.Children.count(this.props.children) > 2 || isMobile ? "space-between": "space-between",
            // flexDirection: this.props.mode === "footer" ? "column": "row"
            overflowY: "auto", overflowX: "hidden",
            width: "100%",
            height:"100%",
            display: "flex",
            flexDirection: "column"            
          },
          ...this.props.style,
        }}
      > 
        {this.props.mode === "footer" ? (
          <div id="content"
          style={{...styles.container,            
            ...{
              width: "100%",
              display: "flex",
              flexGrow:1,
              alignContent: "flex-start",
              height:this.props.mode === "footer" ? "" :"calc(100vh - 100px)",
              justifyContent: React.Children.count(this.props.children) > 2 || isMobile ? "space-between": "flex-end"}
            , ...this.props.style
            } }>

            {React.Children.toArray(this.props.children).filter(
              (x, i) => i !== React.Children.count(this.props.children) - 1
            )}
          </div>
        ) : (
          this.props.children
        )}

        {this.props.mode === "footer" &&
          React.Children.toArray(this.props.children)[React.Children.count(this.props.children) - 1]}
      </Element>
    );
  }
}

const styles = {
  container: {
    // width: "100vw",
    // display: "flex",
  }
};
