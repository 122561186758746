import React from "react";
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";

import "./style/roma_polsum.css";

import { StartView } from "./views/StartView";
import { HomeView } from "./views/HomeView";
import { OfferView } from "./views/OfferView";
import { ImpressumView } from "./views/ImpressumView";
import { DatenschutzView } from "./views/DatenschutzView";
import { FeedbackView } from "./views/FeedbackView";
import { KontaktView } from "./views/KontaktView";
import { LoginView } from "./views/LoginView";
import { DownloadView } from "./views/DownloadView";
import { WorkingView } from "./views/WorkingView"
import HttpService from "./services/HttpService";


var moment = require('moment')
const { isHoliday } = require('moment-feiertage');

const config = require('./config')

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.isOpen(),
      cart: {
        orderPrice: 0,
        order: [
        ]
      },
      offerCart: {
        orderPrice: 0,
        order: []
      },
      offer: undefined,
      deliveryTime: 30,
      takeawayTime: 15,
      displayWIP: false,
      filter: {
        text: [],
        allowedCategories: undefined,
        disabledDishes: undefined, 
        disabledExtras: undefined,
        disabledMinus: undefined,
        filterSize: undefined,
      },
    };
    this.updateCart = this.updateCart.bind(this);
    this.addOfferToCart = this.addOfferToCart.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.updateOffer = this.updateOffer.bind(this);
    this.updateDeliveryTime = this.updateDeliveryTime.bind(this);
    this.updateTakeawayTime = this.updateTakeawayTime.bind(this);
    this.isOpen = this.isOpen.bind(this);
    this.setFilter = this.setFilter.bind(this);

    HttpService.getDisplayWIP().then(data => {
      this.setState(Object.assign({}, this.state, { displayWIP: data.displayWIP }));
    });

    HttpService.getIsOpen().then(data => {
      this.setState(Object.assign({}, this.state, { open: this.isOpen() || data.open}));
    });

  }

  setFilter(filter) {

    this.setState(filter)

  }

  isOpen(){
    // Monday = 0, .... Sunday = 6
    moment.locale('de');

    // Check if day is open!
    var openDay = (moment().weekday() !== 1) || (moment().weekday() === 1 && moment().isHoliday('NW'))

    var openTime = moment().isBetween(moment('17:00:00', 'hh:mm:ss'), moment('22:30:00', 'hh:mm:ss'))

    return (openDay || isHoliday) && openTime
  }

  componentDidMount(props) {
    // HttpService.getIsOpen().then(data => {
    //   this.setState(Object.assign({}, this.state, { open: this.isOpen() || data }));
    // });
  }

  updateDeliveryTime(time) {
    this.setState(Object.assign({}, this.state, { deliveryTime: time }));
  }

  updateTakeawayTime(time) {
    this.setState(Object.assign({}, this.state, { takeawayTime: time }));
  }

  // Eventuell direkt aus offer view aufrufen und nicht über HomeView nach oben reichen.
  updateOffer(offer) {
    var state = this.state
    state.offer = offer
    if(offer === undefined) {
      state.filter.allowedCategories = undefined
      state.filter.disabledDishes = undefined
      state.filter.disabledExtras = undefined
      state.filter.disabledMinus = undefined
      state.filter.disabledMinus = undefined
    } else {
      state.filter.allowedCategories = offer.filter.allowedCategories
      state.filter.disabledDishes = offer.filter.disabledDishes
      state.filter.disabledExtras = offer.filter.disabledExtras
      state.filter.disabledMinus = offer.filter.disabledMinus
      state.filter.disabledMinus = offer.filter.filterSize
    }
    
    this.setState(state)

    // this.setState(Object.assign({}, this.state, { offer: offer }));
  }

  updateCart(cart) {
    var state = this.state;

    if (this.state.offer !== undefined) {
      state.offerCart = cart;
      this.setState(state);
    } else {
      state.cart = cart;
      this.setState(state);
    }
  }

  addOfferToCart(offerExtras, offer) {
    var state = this.state;

    state.offerCart.order.forEach(x => x.dishes.forEach(c => (c.preis = 0)));

    var order = {
      dishes: state.offerCart.order.map(x => x.dishes[0]),
      offer: state.offer.name,
      offerExtras: offerExtras,
      price: state.offer.price
    };
    state.cart.orderPrice += parseFloat(state.offer.price);
    state.cart.order.push(order);

    state.offerCart = {
      orderPrice: 0,
      order: []
    };
    this.setState(state);
  }

  addToCart(order, orderPrice) {
    if (this.state.offer != undefined) {
      if (this.checkOfferCondition()) {
        var state = this.state;
        state.offerCart.orderPrice += parseFloat(orderPrice);
        order.price = 0;
        order.dishes.forEach(x => {
          x.selection = this.state.offer.filter.defaultSize;
          x.price = 0;
        });
        state.offerCart.order.push(order);
        this.setState(state);
      }
    } else {
      var state = this.state;
      state.cart.orderPrice += parseFloat(orderPrice);
      state.cart.order.push(order);
      this.setState(state);
    }
  }

  checkOfferCondition() {
    var condVal = true;

    for (var i = 0; i < this.state.offer.cond.length; i++) {
      var x = this.state.offer.cond[i];
      if (x.name === "length") {
        condVal = condVal && this.state.offerCart.order.length < x.value;
      }
    }
    return condVal;
  }

  render() {
    return (
      <Router>
        <Route
          key={1}
          exact
          path="/"
          render={props => this.state.displayWIP ? <WorkingView {...props} cart={this.state.cart} /> : <StartView {...props} cart={this.state.cart} />}
        />

        {!this.state.displayWIP &&
        <div>
        <Route
          key={2}
          exact
          path="/Home"
          render={props => (
            <HomeView
              {...props}
              isOpen={this.isOpen}
              cart={this.state.cart}
              offerCart={this.state.offerCart}
              offer={this.state.offer}
              addToCart={this.addToCart}
              addOfferToCart={this.addOfferToCart}
              updateCart={this.updateCart}
              updateOffer={this.updateOffer}
              removeOffer={this.removeOffer}
              updateDeliveryTime={this.updateDeliveryTime}
              deliveryTime={this.state.deliveryTime}
              updateTakeawayTime={this.updateTakeawayTime}
              takeawayTime={this.state.takeawayTime}
              filter={this.state.filter}
              setFilter={this.setFilter}
            />
          )}
        />
     
        <Route
          key={3}
          exact
          path="/Angebote"
          render={props => (
            <OfferView
              {...props}
              isOpen={this.isOpen}
              cart={this.state.cart}
              offerCart={this.state.offerCart}
              offer={this.state.offer}
              addToCart={this.addToCart}
              addOfferToCart={this.addOfferToCart}
              updateCart={this.updateCart}
              updateOffer={this.updateOffer}
              removeOffer={this.removeOffer}
              updateDeliveryTime={this.updateDeliveryTime}
              deliveryTime={this.state.deliveryTime}
              updateTakeawayTime={this.updateTakeawayTime}
              takeawayTime={this.state.takeawayTime}
              filter={this.state.filter}
            />
          )}
        /> 
        <Route
          key={4}
          exact
          path={"/Impressum"}
          render={props => (
            <ImpressumView
              {...props}
              isOpen={this.isOpen}
              cart={this.state.cart}
              updateDeliveryTime={this.updateDeliveryTime}
              deliveryTime={this.state.deliveryTime}
              updateTakeawayTime={this.updateTakeawayTime}
              takeawayTime={this.state.takeawayTime}
              filter={this.state.filter}
            />
          )}
        />

        <Route
          key={5}
          exact
          path={"/Datenschutz"}
          render={props => (
            <DatenschutzView
              {...props}
              isOpen={this.isOpen}
              cart={this.state.cart}
              updateDeliveryTime={this.updateDeliveryTime}
              deliveryTime={this.state.deliveryTime}
              updateTakeawayTime={this.updateTakeawayTime}
              takeawayTime={this.state.takeawayTime}
              filter={this.state.filter}
            />
          )}
        />

        <Route
          key={6}
          exact
          path={"/Feedback"}
          render={props => (
            <FeedbackView
              {...props}
              isOpen={this.isOpen}
              cart={this.state.cart}
              updateDeliveryTime={this.updateDeliveryTime}
              deliveryTime={this.state.deliveryTime}
              updateTakeawayTime={this.updateTakeawayTime}
              takeawayTime={this.state.takeawayTime}
              filter={this.state.filter}
            />
          )}
        />

        <Route
          key={7}
          exact
          path={"/Kontakt"}
          render={props => (
            <KontaktView
              {...props}
              isOpen={this.isOpen}
              cart={this.state.cart}
              updateDeliveryTime={this.updateDeliveryTime}
              deliveryTime={this.state.deliveryTime}
              updateTakeawayTime={this.updateTakeawayTime}
              takeawayTime={this.state.takeawayTime}
              filter={this.state.filter}
            />
          )}
        /> 
        </div>
        }

        <Route
          key={8}
          exact
          path={"/internal/login"}
          render={props => <LoginView />}
        />
        <Route
          key={9}
          exact
          path={"/internal/app"}
          render={props => <DownloadView />}
        />
      </Router>
    );
  }
}

export default App;
