import React from "react";

import { withRouter } from "react-router-dom";
import {
  Button,
  TextField,
  Checkbox,
  Select,
  Dialog
} from "react-md";

import FeedbackService from "../services/FeedbackService";

const categories = [
  "Technischer Fehler",
  "Bedieung/Steuerung/User Interface",
  "Anregung zur Webseite",
  "Allgemeine Anregung zur Pizzeria",
  "Sonstiges"
];

const origin = [
  "Hinzufügen von Gerichten",
  "Hinzufügen von Gerchten zu einem Angebot",
  "Anpassen eines Gerichts (Extras, Zutaten entfernen etc.)",
  "Eingabe von Lieferinformationen",
  "Senden der Bestellung",
  "Sonstiges"
];

const device = ["PC", "MAC", "Smartphone", "Tablet", "Sonstiges"];

const browser = [
  "Chrome",
  "Firefox",
  "Safari",
  "Edge",
  "Internet Explorer",
  "Sonstiges"
];

class FeedbackFormular extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      category: "",
      origin: "",
      timeOfError: "",
      device: "",
      browser: "",
      feedback: "",
      dataprivacyErrMsg: "",
      dataprivacyChecked: false,
      sendSuccessVisible: false,
      responseMsg: "",
      responseError: true
    };
    this.sendFeedback = this.sendFeedback.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  show = () => {
    this.setState(Object.assign({}, this.state, { sendSuccessVisible: true }));
  };

  hide = () => {
    this.setState(Object.assign({}, this.state, { sendSuccessVisible: false }));
  };

  sendFeedback() {
    var state = this.state;
    var update = false;

    var feedback = {
      category: this.state.category,
      origin: this.state.origin,
      timeOfError: this.state.timeOfError,
      device: this.state.device,
      browser: this.state.browser,
      feedback: this.state.feedback,
      timestamp: new Date(),
    };

    if (
      !this.state.dataprivacyChecked ||
      this.state.dataprivacyChecked == undefined
    ) {
      state.dataprivacyErrMsg =
        "Bitte bestätigen Sie, dass Sie die Datenschutzerklärung gelesen und zur Kenntniss genommen haben.";
      update = true;
    } else {
      state.dataprivacyErrMsg = "";
    }

    this.setState(state);

    if (update) {
      // Error occured
      return;
    }

    FeedbackService.addFeedback(feedback).then(data => {
        this.setState(
          Object.assign({}, this.state, {
            sendSuccessVisible: true,
            responseError: false,
            responseMsg: data.message
          })
        );
      })
      .catch(data => {
        this.setState(
          Object.assign({}, this.state, {
            sendSuccessVisible: true,
            responseError: true,
            responseMsg: data.message ? data.message : data
          })
        );
      });;
  }

  render() {
    return (
      <div
        style={{ padding: "15px", display: "flex", flexDirection: "column" }}
      >
        <Select
          id="deleteIngredient"
          onChange={txt => {
            this.setState(Object.assign({}, this.state, { category: txt }));
          }}
          placeholder="Art der Meldung"
          inputStyle={{
            fontFamily: "'Dosis', sans-serif"
          }}
          menuItems={categories}
          position={Select.Positions.BELOW}
        />

        {(this.state.category === "Technischer Fehler" ||
          this.state.category === "Bedieung/Steuerung/User Interface") && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              label="Zeitpunkt des Fehlers"
              placeholder="Wann ist der Fehler ca. aufgetreten"
              onChange={txt =>
                this.setState(
                  Object.assign({}, this.state, { timeOfError: txt })
                )
              }
            />

            <Select
              id="deleteIngredient"
              onChange={txt => {
                this.setState(Object.assign({}, this.state, { origin: txt }));
              }}
              placeholder="Wo ist der Fehler aufgetreten?"
              inputStyle={{
                fontFamily: "'Dosis', sans-serif"
              }}
              menuItems={origin}
              position={Select.Positions.BELOW}
            />

            <Select
              id="deleteIngredient"
              onChange={txt => {
                this.setState(Object.assign({}, this.state, { device: txt }));
              }}
              placeholder="Was für ein Gerät verwenden Sie?"
              inputStyle={{
                fontFamily: "'Dosis', sans-serif"
              }}
              menuItems={device}
              position={Select.Positions.BELOW}
            />
            <Select
              id="deleteIngredient"
              onChange={txt => {
                this.setState(Object.assign({}, this.state, { browser: txt }));
              }}
              placeholder="Was für einen Browser verwenden Sie?"
              inputStyle={{
                fontFamily: "'Dosis', sans-serif"
              }}
              menuItems={browser}
              position={Select.Positions.BELOW}
            />
          </div>
        )}

        <TextField
          label="Anmerkung / Feedback"
          //   placeholder=""
          onChange={txt =>
            this.setState(Object.assign({}, this.state, { feedback: txt }))
          }
        />

        <Checkbox
          style={{ display: "inline" }}
          id="Datenschutzerklärung"
          name="simple-checkboxes[]"
          onChange={() =>
            this.setState(
              Object.assign({}, this.state, {
                dataprivacyChecked: !this.state.dataprivacyChecked
              })
            )
          }
          label={
            <div
              style={{
                fontFamily: "'Dosis', sans-serif",
                color: this.state.dataprivacyErrMsg != "" ? "#f44336" : ""
              }}
            >
              Ich habe die <a href="/Datenschutz">Datenschutzerklärung</a>{" "}
              gelesen und zur Kenntnis genommen.
            </div>
          }
          value="material-design"
        />
        {this.state.dataprivacyErrMsg != "" && (
          <div
            style={{
              fontFamily: "'Dosis', sans-serif",
              color: "#f44336",
              fontWeight: 700
            }}
          >
            {this.state.dataprivacyErrMsg}
          </div>
        )}

        <Button
          style={{ backgroundColor: "rgb(95, 173, 70)", margin: 10 }}
          raised
          onClick={() => this.sendFeedback()}
        >
          Feedback senden.
        </Button>

        <Dialog
          id="simple-list-dialog"
          visible={this.state.sendSuccessVisible}
          title={this.state.responseMsg}
          contentStyle={{
            backgroundColor: this.state.responseError
              ? "rgba(232, 30, 37, 0.6)"
              : ""
          }}
          dialogStyle={{
            backgroundColor: this.state.responseError
              ? "rgba(232, 30, 37, 0.6)"
              : ""
          }}
          titleStyle={{
            color: this.state.responseError ? "white" : "",
            fontFamily: "'Dosis', serif"
          }}
          onHide={this.hide}
          containFocus={false}
          focusOnMount={false}
        >
          
        </Dialog>

      </div>
    );
  }
}

export default withRouter(FeedbackFormular);

const styles = {};
