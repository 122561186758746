import React, { Component } from "react";
import { Divider } from "react-md";

export default class Title extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ ...this.props.style }}>
        <div style={styles.menuHeadContainer}>
          {this.props.icon !== undefined && this.props.icon !== "" && (
            <img
              src={this.props.icon}
              style={{
                ...{ width: 30, height: "auto" },
                ...this.props.iconStyle
              }}
              alt="Logo"
            />
          )}

          {this.props.link && (
            <h2
              style={{...{
                textAlign: "left",
                fontFamily: "'Dosis', serif",
                fontWeight: "700",
                margin: 0
              }, ...this.props.fontStyle}}
            >
              <a href={this.props.link}> {this.props.title}</a>
            </h2>
          )}

          {!this.props.link && (
            <h2
              style={{...{
                textAlign: "left",
                fontFamily: "'Dosis', serif",
                fontWeight: "700",
                margin: 0
              }, ...this.props.fontStyle}}
            >
              {this.props.title}
            </h2>
          )}
        </div>

        {this.props.divider && (
          <Divider style={{ marginBottom: 20, marginTop: 15 }} inset />
        )}
      </div>
    );
  }
}

const styles = {
  menuHeadContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    paddingLeft: "30px",
    paddingTop: "10px"
  }
};
