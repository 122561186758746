"use strict";

import React from 'react';
import { withRouter } from 'react-router-dom'
// import titleLogo from '../res/img/titleLogo.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(fab);

// const Desktop = props => <Responsive {...props} minWidth={992} />;
// const Tablet = props => <Responsive {...props} minWidth={768} maxWidth={991} />;
// const Mobile = props => <Responsive {...props} maxWidth={767} />;
// const Default = props => <Responsive {...props} minWidth={768} />;


const config = require("../../config/Config");

/**
 * Footer bar comment.
 */
class Footer extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
           <div style={{...styles.footer, ...this.props.style}}>
               <div>
               <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <p>{config.info.title}</p>
                <p>{config.info.subTitle.address}</p>
                <a href={config.info.subTitle.telAttr}>
                  {config.info.subTitle.tel}
                </a>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  marginBottom: 20
                }}
              >
                {config.info.social.map((x, i) => (
                  <FontAwesomeIcon
                    key={`mediaContact_${i}`}
                    icon={["fab", `${x.name}`]}
                    color="#3C5A99"
                    size="2x"
                    onClick={() => window.open(x.url, "_blank")}
                  />
                ))}
              </div>
               </div>
           </div>
        );
    }
};

export default withRouter(Footer);

const styles= {
    footer: {
        backgroundColor: "#FF4F29",
        width: "100%",
        minHeight: "150px",
        paddingBottom: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    

}