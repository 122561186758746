const info = {
  title: "Pizzeria Roma Polsum",
  subTitle: {
    address: "Kolpingstr. 2 45768 Marl",
    tel: "02365 507 813",
    telAttr: "tel:02365 507813",
  },
  social: [
    {
      name: "facebook",
      url:
        "https://de-de.facebook.com/pages/category/Pizza-Place/Pizzeria-Roma-Marl-Polsum-120282051363696/",
    },
    // {twitter: "",},
    // {instagram: "",},
    // {youtube: ""},
  ],
};

// REACT_APP_LOCAL=1 npm run start | $env:REACT_APP_LOCAL=1; npm run start
const ip = process.env.REACT_APP_LOCAL
  ? "http://localhost:3001"
  : "https://pizzeriaroma-polsum.de";

if(process.env.REACT_APP_LOCAL) {
  console.log(`Test environment! ${ip}`)
}
  

const sideNumber = 30;

const defaultCat = "Pizza";
const defaultSelection = {
  Pizza: "Normal",
  Salat: "Cocktail",
};

const minPrice = 10;

const menu = [
  {
    name: "Gerichte & Getränke",
    selection: "Pizza",
    path: "/Home",
    icon: "/res/img/icons/expand-button.svg",
    iconPressed: "/res/img/icons/expand-arrow.svg",
    text: "Bitte wählen Sie eine Kategorie aus.",
    items: [
      {
        name: "Pizza",
        selection: "Pizza",
        path: "/Home",
        icon: "/res/img/icons/pizza.svg",
        iconPressed: "",
        text:
          "Zu allen Pizzen servieren wir 2 Pizzabrötchen mit Kräuterbutte oder Aoli",
        items: [],
      },
      {
        name: "Nudeln",
        selection: "Nudeln",
        path: "/Home",
        icon: "/res/img/icons/spaguetti.svg",
        iconPressed: "",
        text:
          "Zu allen Nudeln servieren wir 2 Pizzabrötchen mit Kräuterbutte oder Aoli",
        items: [],
      },
      {
        name: "Gnocchi",
        selection: "Gnocchi",
        path: "/Home",
        icon: "/res/img/icons/fried.svg",
        iconPressed: "",
        text: "",
        items: [],
      },
      {
        name: "Baguette",
        selection: "Baguette",
        path: "/Home",
        icon: "/res/img/icons/sandwich.svg",
        iconPressed: "",
        text: "",
        items: [],
      },
      {
        name: "Schnitzel u. Pommes",
        selection: "Schnitzel u. Pommes",
        path: "/Home",
        icon: "/res/img/icons/steak.svg",
        iconPressed: "",
        text:
          "Zu jedem Schnitzel servieren wir einen Beilagensalat aus grünene Salat, Tomaten und Gurken.",
        items: [],
      },
      {
        name: "Indisch-Pakistanisch",
        selection: "Indisch-Pakistanisch",
        path: "/Home",
        icon: "/res/img/icons/fried.svg",
        iconPressed: "",
        text: "",
        items: [],
      },
      {
        name: "Gyros",
        selection: "Gyros",
        path: "/Home",
        icon: "/res/img/icons/fried.svg",
        iconPressed: "",
        text: "",
        items: [],
      },
      {
        name: "Salat",
        selection: "Salat",
        path: "/Home",
        icon: "/res/img/icons/salad.svg",
        iconPressed: "",
        text:
          "Zu allen Pizzen servieren wir 2 Pizzabrötchen mit Kräuterbutte oder Aoli",
        items: [],
      },
      {
        name: "Sonstiges",
        selection: "Sonstiges",
        path: "/Home",
        icon: "/res/img/icons/fried.svg",
        iconPressed: "",
        text: "",
        items: [],
      },
      {
        name: "Saucen, Dips, ...",
        selection: "Saucen",
        path: "/Home",
        icon: "/res/img/icons/fried.svg",
        iconPressed: "",
        text: "",
        items: [],
      },
      {
        name: "Getränke",
        selection: "Getränke",
        path: "/Home",
        icon: "/res/img/icons/coke.svg",
        iconPressed: "",
        text: "",
        items: [],
      },
    ],
  },
  {
    name: "Angebote",
    selection: "Angebote",
    path: "/Angebote",
    text: "",
    items: [],
  },
  {
    name: "Feedback",
    selection: "Feedback",
    path: "/Feedback",
    text: "",
    items: [],
  },
  {
    name: "Kontakt",
    selection: "Kontakt",
    path: "/Kontakt",
    text: "",
    items: [],
  },
];

module.exports = {
  info,
  ip,
  defaultSelection,
  defaultCat,
  minPrice,
  menu,
  sideNumber,
};
