import React, { useState } from "react";

import { withRouter, Link } from "react-router-dom";
import { Card, Button } from "react-md";
import { CloseSVGIcon } from "@react-md/material-icons";


import Title from "./Title";
import { isMobile } from "react-device-detect";


function MenuItemList(props) {
  const [expand, setExpand] = useState(
    props.expanded || props.clicked === props.item.name
  );


  return (
    <div style={{
      ...styles.menuContainer, ...{
        pointerEvents: props.parent === "Gerichte & Getränke" && props.allowedCategories !== undefined && !props.allowedCategories.includes(props.item.name) ? "none" : "",
        opacity: props.parent === "Gerichte & Getränke" && props.allowedCategories !== undefined && !props.allowedCategories.includes(props.item.name) ? 0.4 : 1,
      }
    }}>
      <Button
        raised={
          props.clicked === props.item.name ||
          props.item.name === props.selected
        } //props.item.name === props.selected}
        style={{
          ...props.style,
          ...styles.menuItem,
          ...{
            backgroundColor:
              props.clicked === props.item.name ||
                props.item.name === props.selected
                ? // props.item.name === props.selected
                "rgba(255, 228, 107, 0.5)"
                : "",
            display: "flex",
            justifyContent: "flex-start"
          }
        }}
        onClick={() => {
          setExpand(!expand);
          props.onClickMenuItem(props.level, props.item);
        }}
      >
        {(props.item.icon !== undefined &&
          props.item.icon !== "") &&
          (!expand ||
            (props.item.iconPressed === undefined ||
              props.item.iconPressed === "")) &&
          (
            <img
              src={props.item.icon}
              height={"20px"}
              style={{ marginRight: 10 }}
              alt="icon"
            />
          )}

        {props.item.iconPressed !== undefined &&
          props.item.iconPressed !== "" &&
          (expand) && (
            <img
              src={props.item.iconPressed}
              height={"20px"}
              style={{ marginRight: 10 }}
              alt="icon"
            />
          )}

        <div>{props.item.name}</div>
      </Button>

      {props.item.items.length > 0 && (
        <div
          style={{
            ...props.styleItem,
            ...{
              display: expand ? "" : "none",
              marginLeft: props.level * 18
            }
          }}
        >
          {props.item.items.map((x, i) => (
            <MenuItemList
              key={`funcMenuItem_${i}`}
              allowedCategories={props.allowedCategories}
              parent={props.item.name}
              item={x}
              expanded={expand}
              level={props.level + 1}
              items={props.item.items}
              subitems={false}
              style={props.style}
              onClickMenuItem={props.onClickMenuItem}
              selected={props.selected}
            />
          ))}
        </div>
      )}
    </div>
  );
}

class Sidemenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: true
    };

    this.menuToggle = this.menuToggle.bind(this);
    this.sidemenu = this.sidemenu.bind(this);
    this.menuContent = this.menuContent.bind(this);
    this.toggleBtn = this.toggleBtn.bind(this);
  }

  sidemenu = () => ({
    float: "left",
    marginTop: isMobile ? "" : 10,
    width: isMobile ? "100%" : "",
    height: isMobile ? "100%" : "100%", //calc(100vh - 80px)" : "100%",
    maxWidth: this.state.showMenu ? "100%" : "50px",
    overflow: "hidden",
    transition: "max-width 1s ease-in-out",
    WebkitTransition: "max-width 1s ease-in-out"
  });

  menuContent = () => ({
    overflow: "hidden",
    opacity: this.state.showMenu ? 1 : 0
  });

  toggleBtn = () => ({
    margin: 5,
    float: "right"
  });

  menuToggle = () => {
    if (isMobile) {
      this.props.showMenu();
    } else {
      this.setState(
        Object.assign({}, this.state, { showMenu: !this.state.showMenu })
      );
      if (!this.props.overlap) {
        this.props.setMenuWidth(!this.state.showMenu ? "15%" : "50px");
      }
    }
  };

  render() {
    return (
      <div>
        <div style={{ position: isMobile ? "" : "absolute" }}>
          <Card style={{ ...this.sidemenu(), ...this.props.style }}>
            <div style={{display: "flex", flexFlow: "row", justifyContent: "space-between"}}>

            
            <Title
              title="Menü"
              divider={true}
              style={{ overflow: "hidden" }}
              icon={undefined}
              iconStyle={{
                ...{ width: 30, height: "auto", marginRight: 20 },
                ...this.menuContent()
              }}
            />
            <Button
              buttonType="icon"
              onClick={() => this.menuToggle()}
            ><CloseSVGIcon /></Button>

            </div>

            <div
              style={{
                ...{
                  height: isMobile
                    ? "70vh" //"calc(100% - 110px)"
                    : "calc(100vh - 330px)",
                  overflow: "auto"
                }
              }}
            >
              {this.props.menu.map((x, i) => (
                <MenuItemList
                  key={`menuItem_${i}`}
                  allowedCategories={this.props.allowedCategories}
                  item={x}
                  clicked={this.props.clicked}
                  level={1}
                  expanded={false}
                  items={this.props.menu}
                  subitems={false}
                  style={this.menuContent()}
                  onClickMenuItem={this.props.onClickMenuItem}
                  selected={this.props.selected}
                />
              ))}
            </div>

            <div style={{ display: "flex", flexWrap: "wrap", padding: 5 }}>
              <Link to="/Impressum" style={{ marginRight: 10 }}>
                Impressum
              </Link>
              <Link to="/Datenschutz">Datenschutzerklärung</Link>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default withRouter(Sidemenu);

const styles = {
  menuItem: {
    width: "100%",
    fontSize: "18px",
    // textAlign: "left",
    marginTop: 10,
    marginBottom: 10,
    overflow: "hidden",
    whiteSpace: "nowrap",
    paddingLeft: 30,
    paddingRight: 30
  },
  menuContainer: {
    // height: "calc(100% - 90px)",
    display: "flex",
    flexDirection: "column",
    alignItem: "center",
    justifyContent: "center"
  },
  menuHeadContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    paddingLeft: 30
  }
};
