import HttpService from './HttpService';
const config = require("../config/Config");


export default class FileService {

    static baseURL() {return `${config.ip}/file` }

    static getCashierApp(){
       return new Promise((resolve, reject) => {
           HttpService.getFile(`${FileService.baseURL()}/getCashier`, function(data) {
               resolve(data);
           }, function(textStatus) {
               reject(textStatus);
           });
       });
    }
}