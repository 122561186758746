import React from "react";

import "../style/HomeView.css";

import { isMobile } from "react-device-detect";
import Login from "../components/default/Login";
import UserService from "../services/UserService";
import FileService from "../services/FileService";
import { Button } from "react-md";


const config = require("../config/Config");
var fileDownload = require("js-file-download");

export class DownloadView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isAuthenticated: UserService.isAuthenticated(),
        downloadState: "-"
    };
    this.onLogin = this.onLogin.bind(this);
    this.onLoginFailed = this.onLoginFailed.bind(this);
    this.downloadAPK = this.downloadAPK.bind(this)
  }

  componentDidMount(props) {}

   downloadAPK = () => {

    this.setState(Object.assign({}, this.state, {downloadState: "Download gestartet"}))

    FileService.getCashierApp()
    .then(res => {
        console.log("fetch")


      res.blob().then(function(blobData) {
        let url = window.URL.createObjectURL(blobData);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'roma_cashier.apk';
        a.click();




        // // this.setState(Object.assign({}, this.state, {downloadState: "App wird zum speichern vorbereitet"}))

        // fileDownload(blobData, "roma_cashier.apk");

        // // const href = window.URL.createObjectURL(blobData)
        // // const link = React.createRef()

        // // link.current.download = "roma_cashier.apk"
        // // link.current.href = href
          
        // // link.current.click()

      });
      
    })
    .catch(res => alert(res));

  }

  onLogin = () => {
    this.setState(Object.assign({}, this.state, {isAuthenticated: true}))

    // FileService.getCashierApp()
    //   .then(res => {
    //     res.blob().then(function(blobData) {
    //       fileDownload(blobData, "roma_cashier.apk");
    //     });
    //     //
    //   })
    //   .catch(res => alert(res));


  };

  onLoginFailed = e => {
    alert(`Login failed ${e}`);
  };

  render() {
    // if (UserService.isAuthenticated()) {
    //   this.downloadAPK();
    // }

    return (
      <div className={"flexBoxColumn"} style={{ height: "100%" }}>
        {UserService.isAuthenticated() || this.state.isAuthenticated ? (
          <div>

            <div>Sie sind eingeloggt.</div>
            <Button style={{padding: 10, backgroundColor: "green", marginTop: 20}} onClick={() => this.downloadAPK()}>Download App</Button>
            <div>{this.state.downloadState}</div>
         
         </div>
        ) : (
            <Login onLogin={this.onLogin} onLoginFailed={this.onLoginFailed} />
        )}
      </div>
    );
  }
}

const styles = {};
