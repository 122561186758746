import React from "react";

import { Card, } from "@react-md/card";
import { TextField } from "@react-md/form";
import { Button } from "@react-md/button";
import GridLoader from "react-spinners/GridLoader";
import { SearchSVGIcon, CloseSVGIcon, ShoppingCartSVGIcon } from "@react-md/material-icons";
import { Badge, BadgeContainer } from "@react-md/badge";


import Sidebar from "../components/Sidebar";
import DishCard from "../components/DishCard";
import Footer from "../components/default/Footer";
import Content from "../components/default/Content";
import ContentContainer from "../components/default/ContentContainer";
import SidebarContainer from "../components/default/SidebarContainer";

import "../style/HomeView.css";
import Sidemenu from "../components/Sidemenu";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

import DishService from "../services/DishService";
import FacebookSmall from "../components/FacebookSmall";

import { isMobile } from "react-device-detect";
import HttpService from "../services/HttpService";
import Header from "../components/default/Header";
import HeaderRight from "../components/default/HeaderRight";

import titleLogo from "../res/img/logo.svg";
import VisibilitySensor from "react-visibility-sensor";

// import { Keyframes, Spring, animated, Easing } from "react-spring/renderprops";

import Scroll from "react-scroll";
const scroll = Scroll.animateScroll;

const config = require("../config/Config");

export class HomeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: false,
      showSidbarLeft: false,
      activeOffer: "-1",
      dishes: undefined,
      menu: [],
      menuShown: false,
      sidebarIsOpen: false,
      selectedCat: config.defaultCat,
      defaultSelection: config.defaultSelection[config.defaultCat],
      offerCart: this.props.offerCart,
      cart: this.props.cart,
      sidebarIsVisible: true,
      filter: {
        text: [],
        allowedCategories: this.props.offer === undefined ? undefined : this.props.offer.filter.allowedCategories,
        disabledDishes: this.props.offer === undefined ? undefined : this.props.offer.filter.disabledDishes,
        disabledExtras: this.props.offer === undefined ? undefined : this.props.offer.filter.disabledExtras,
        disabledMinus: this.props.offer === undefined ? undefined : this.props.offer.filter.disabledMinus,
        filterSize: this.props.offer === undefined ? undefined : this.props.offer.filter.filterSize,
      },
      currentSide: 1,
      pulseCartButton: false,
    };

    // this.filter = this.filter.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.updateCart = this.updateCart.bind(this);
    this.onClickMenuItem = this.onClickMenuItem.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.openSidebar = this.openSidebar.bind(this);
    this.filter = this.filter.bind(this);
    this.setTextFilter = this.setTextFilter.bind(this);
    this.removeOffer = this.removeOffer.bind(this);
    this.addOfferToCart = this.addOfferToCart.bind(this);
    this.filterDishSet = this.filterDishSet.bind(this);
    this.getSideNumbers = this.getSideNumbers.bind(this);
    this.sidebarIsVisible = this.sidebarIsVisible.bind(this);
  }

  componentDidUpdate() {

  }

  componentDidMount(props) {
    HttpService.getDeliveryTime().then((data) => {
      this.props.updateDeliveryTime(data.time);
    });

    HttpService.getTakeawayTime().then((data) => {
      this.props.updateTakeawayTime(data.time);
    });

    setInterval(() => {
      HttpService.getDeliveryTime().then((data) => {
        this.props.updateDeliveryTime(data.time);
      });

      HttpService.getTakeawayTime().then((data) => {
        this.props.updateTakeawayTime(data.time);
      });
    }, 1500);

    // fetching all dishes!
    DishService.getDishes().then((data) => {
      var state = this.state;

      console.log(data)

      state.dishes = data;
      this.setState(state);
    });

    DishService.getExtras().then((data) => {
      var state = this.state;
      state.extras = data;
      this.setState(state);
    });

    if (this.props.location.state !== undefined) {
      this.props.updateOffer(this.props.location.state.offer);
    }
  }

  updateCart(cart) {
    this.props.updateCart(cart);
  }

  addOfferToCart(offerExtras) {
    this.props.addOfferToCart(offerExtras);
    this.removeOffer();
  }

  addToCart(order, orderPrice) {
    this.props.addToCart(order, orderPrice);
    this.setState({ ...this.state, ...{ pulseCartButton: true } });
  }

  changeCategory(cat) {
    var state = this.state;
    state.selectedCat = cat;
    this.setState(state);
  }

  onClickMenuItem(level, item) {
    this.props.history.push({
      pathname: item.path,
      state: { clicked: item.name },
    }); //this.props.location.pathname === path ? "": name}})

    if (item.name != undefined) {
      var menuOpen = this.state.showSidbarLeft;

      if (isMobile && item.items.length <= 0) {
        menuOpen = !menuOpen;
      }

      this.setState(
        Object.assign({}, this.state, {
          selectedCat: item.selection,
          showSidbarLeft: menuOpen,
          sidebarIsOpen: false,
        })
      );

      if (item.items.length <= 0) {
        scroll.scrollToTop({
          duration: 1000,
          smooth: true,
          containerId: "contentContainer",
        });
      }
    }
  }

  openMenu() {
    this.setState(
      Object.assign({}, this.state, {
        // menuShown: !this.state.menuShown,
        showSidbarLeft: !this.state.showSidbarLeft,
        sidebarIsOpen: false,
      })
    );
  }

  openSidebar() {
    this.setState(
      Object.assign({}, this.state, {
        sidebarIsOpen: !this.state.sidebarIsOpen,
        showSidbarLeft: false,
      })
    );
  }

  removeOffer() {
    var state = this.state;
    state.offer = undefined;
    // Maybe double. Probably theis will be
    state.filter.disabledDishes = undefined;
    state.filter.disabledExtras = undefined;
    state.filter.disabledMinus = undefined;
    state.filter.filterSize = undefined;
    state.filter.allowedCategories = undefined;

    this.props.updateOffer(undefined);

    // this.setState(state);
  }

  // Search Engine
  setTextFilter(text) {
    var state = this.state;
    if (text.split(" ").length > 0 && text != "") {
      state.filter.text = text.toUpperCase().split(" ");
      // text.split(" ").forEach(x => state.filter.text.add(x.toUpperCase()));
    } else {
      state.filter.text = [];
    }

    this.setState(state);
  }

  filter(x) {
    var val = true;
    if (this.state.filter.text.size == 1 && this.state.filter.text.includes("")) {
      val = true;
    }

    // Text Filter
    if (this.state.filter.text.length > 0) {
      val =
        this.state.filter.text.includes(x.nr) ||
        x.nr.split("").some((c) => this.state.filter.text.some((s) => c.includes(s))) ||
        x.name
          .toUpperCase()
          .split(" ")
          .some((c) => this.state.filter.text.some((s) => c.includes(s))) ||
        x.zutaten.map((c) => c.toUpperCase()).some((c) => this.state.filter.text.some((s) => c.includes(s)));
    }

    // Offer Filter
    if (this.state.filter.disabledDishes != undefined) {
      val =
        val &&
        !(this.state.filter.disabledDishes.includes(x.nr) || this.state.filter.disabledDishes.includes(x.name)) &&
        this.state.filter.allowedCategories.includes(x.kategorie);
    }

    return val;
  }

  filterDishSet() {
    var val = true;
    // When we are in a text search use all items otherwise just those for the category
    var tmpDishes = this.state.dishes[this.state.filter.text.size > 0 ? "all" : this.state.selectedCat];

    if (tmpDishes) {
      // Do the split for the sites
      // if (tmpDishes.length > 0 && tmpDishes.length <= config.sideNumber) {
      if (tmpDishes.length > 0 && tmpDishes.length) {
        // Less than side number, hence no splitting!
        return tmpDishes.filter(this.filter);
      } else {
        // Split the data since we exceed the site number
        return tmpDishes.filter(this.filter);
        // .slice(
        //   this.state.currentSide * config.sideNumber - config.sideNumber,
        //   this.state.currentSide * config.sideNumber
        // )
      }
    } else {
      return [];
    }
  }

  getSideNumbers() {
    var tmpDishes = this.state.dishes[this.state.filter.text.size > 0 ? "all" : this.state.selectedCat];

    if (tmpDishes) {
      return [...Array(Math.ceil(tmpDishes.filter(this.filter).length / config.sideNumber)).keys()];
    } else {
      return [];
    }
  }

  sidebarIsVisible(isVisible) {
    if (this.state.sidebarIsVisible != isVisible) {
      this.setState(Object.assign(this.state, {}, { sidebarIsVisible: isVisible }));
    }
  }

  // PulseContainer = Keyframes.Spring(async (next) => {
  //   // await next({
  //   //   from: {
  //   //     transform: "scale(1)",
  //   //     boxShadow: "0px 0px 0px 0px rgb(255, 58, 7)",
  //   //   },
  //   //   to: {
  //   //     transform: "scale(1.3)",
  //   //     boxShadow: "0px 0px 85px 49px rgb(255, 58, 7)",
  //   //   },
  //   // });
  //   await next({
  //     from: {
  //       transform: "scale(1.3)",
  //       boxShadow: "0px 0px 85px 49px rgb(255, 58, 7)",
  //     },
  //     to: {
  //       transform: "scale(1)",
  //       boxShadow: "0px 0px 0px 0px rgb(255, 58, 7)",
  //     },
  //   });
  // });

  render() {
    return (
      <div className={"flexBoxColumn"} style={{ height: "100vh", justifyContent: "space-between" }}>
        <Header
          title={config.info.title}
          subTitle={config.info.subTitle}
          titleMobile="Roma"
          subTitleMobile=""
          titleLogo={titleLogo}
          openMenu={this.openMenu}
          socialMedia={false}
          openSidebar={this.openSidebar}
          cart={this.props.cart}
        >
          {/* Right of the SupTitle */}
          {isMobile && (
            <HeaderRight>
              <a
                href={config.info.subTitle.telAttr}
                style={{
                  display: "flex",
                  justifyContent: "cneter",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon icon={faPhone} size="lg" color="rgba(0,0,0,0.54)" />
              </a>
              {isMobile && this.openSidebar !== undefined && (
                <Button
                  buttonType="icon"
                  onClick={() =>
                    this.setState(
                      Object.assign({}, this.state, {
                        showSearch: !this.state.showSearch,
                      })
                    )
                  }
                ><SearchSVGIcon /></Button>
              )}
            </HeaderRight>
          )}
        </Header>

        {isMobile && (
          <div
            style={{
              position: "absolute",
              bottom: 20,
              right: 20,
              zIndex: 2000,
              width: "calc(100% - 40px)",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {!this.props.isOpen && (
              <div
                style={{
                  color: "white",
                  backgroundColor: "rgb(255, 58, 7)",
                  width: "100%",
                  padding: 10,
                  marginRight: 10,
                  fontSize: 14,
                  fontWeight: "bold"
                }}
              >
                Geschlossen <br />
                Öffnungszeiten: Di - So, 17:00 - 22:00
              </div>
            )}



            <BadgeContainer >
              <Button
                themeType="contained"
                buttonType="icon"
                style={{ backgroundColor: "rgb(255, 58, 7)" }}
                onClick={() => this.openSidebar()}
              >{this.state.sidebarIsOpen ? <CloseSVGIcon /> : <ShoppingCartSVGIcon />}</Button>
              <Badge style={{top: -15}}>{this.props.cart.order.length}</Badge>
            </BadgeContainer>

          </div>
    )
  }

        <div
  style={{
    width: "100%",
    position: isMobile ? "" : "absolute",
    top: 75,
    // zIndex: isMobile ? "" : 1010,
    position: "absolute",
  }}
>
  <div
    style={{
      opacity: isMobile ? (this.state.showSearch ? "0.7" : "1") : "1",
      transition: " 2s",
      paddingLeft: 20,
      position: "absolute",
      right: isMobile ? undefined : 0,
      zIndex: 1,
      display: isMobile ? "flex" : undefined,
      flexDirection: isMobile ? "row" : undefined,
    }}
  >
    <FacebookSmall />

    {/* Sides */}
    {/* <div style={{ display: "flex" }}>
              {this.state.dishes &&
                this.getSideNumbers().map((x) => (
                  <Button
                    style={{
                      fontSize: 32,
                      width: "unset",
                      minWidth: "unset",
                      padding: 5,
                      paddingRight: 15,
                      paddingLeft: 15,
                      margin: "unset",
                      height: "unset",
                    }}
                    raised={x + 1 === this.state.currentSide}
                    onClick={() => {
                      scroll.scrollToTop({
                        duration: 1000,
                        smooth: true,
                        containerId: "contentContainer",
                      });
                      this.setState(
                        Object.assign({}, this.state, { currentSide: x + 1 })
                      );
                    }}
                  >
                    {x + 1}
                  </Button>
                ))}
            </div> */}
  </div>

  {/* Search */}
  <div
    style={{
      display: "flex",
      right: 0,
      top: 0,
      position: isMobile ? "absolute" : "",
      transition: "1s linear",
      zIndex: 1,
    }}
  >
    <Card
      style={{
        width: isMobile ? (this.state.showSearch ? "100vw" : 0) : "",
        padding: isMobile ? "5px" : "10px",
        transition: "width 0.5s linear",
      }}
    >
      <TextField
        id="search_dish"
        placeholder="Gericht suchen..."
        style={{ overflow: "hidden" }}
        helpText={<div style={{ whiteSpace: "nowrap" }}>Suchen Sie nach Nr., Namen oder Zutaten.</div>}
        onChange={(evt) => this.setTextFilter(evt.target.value)}
      />
    </Card>
  </div>
</div>

  <ContentContainer id={"contentContainer"} scrollable={true} mode={"footer"}>
    <SidebarContainer
      direction={"left"}
      sidebarIsOpen={this.state.showSidbarLeft}
      showMenu={this.openMenu}
      width={"20%"}
      fixed={true}
      from={"left"}
      background={true}
    >
      <Sidemenu
        allowedCategories={this.state.filter.allowedCategories}
        menu={config.menu}
        selected={this.state.filter.text.size > 0 ? "all" : this.state.selectedCat}
        onClickMenuItem={this.onClickMenuItem}
        setMenuWidth={this.setMenuWidth}
        overlap={true}
        showMenu={this.openMenu}
        style={{ marginTop: isMobile ? 0 : 100 }}
        clicked={
          this.props.history.location.state != undefined ? this.props.history.location.state.clicked : undefined
        }
      />
    </SidebarContainer>

    <Content width="40%" style={{ marginTop: isMobile ? 10 : 70 }}>
      {this.state.dishes && this.state.extras ? (
        this.filterDishSet().map((c, i) => (
          // Create a side system
          <DishCard
            key={i}
            nr={c.nr}
            name={c.name}
            ingredients={c.zutaten}
            price={c.preis}
            selections={c.auswahl}
            category={c.kategorie}
            extras={this.state.extras}
            selection={this.state.defaultSelection}
            addToCart={this.addToCart}
            filter={this.state.filter}
          />
        ))
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GridLoader
            // css={override}
            sizeUnit={"px"}
            size={20}
            color={"rgb(255, 79, 41)"}
            loading={true}
          />
        </div>
      )}
    </Content>

    <SidebarContainer
      direction={"right"}
      width={"30%"}
      background={true}
      sidebarIsOpen={this.state.sidebarIsOpen}
      from={"top"}
      style={{ zIndex: isMobile ? 1200 : undefined }}
    >
      <VisibilitySensor onChange={this.sidebarIsVisible}>
        <Sidebar
          isOpen={this.props.isOpen}
          cart={this.state.cart}
          offer={this.props.offer}
          offerCart={this.props.offerCart}
          updateCart={this.props.updateCart}
          sidebarIsOpen={this.state.sidebarIsOpen}
          openSidebar={this.openSidebar}
          removeOffer={this.removeOffer}
          style={{ marginTop: isMobile ? 0 : 100 }}
          addOfferToCart={this.addOfferToCart}
          deliveryTime={this.props.deliveryTime}
          takeawayTime={this.props.takeawayTime}
        />
      </VisibilitySensor>
    </SidebarContainer>
    <Footer />
  </ContentContainer>

{
  !this.state.sidebarIsVisible && !isMobile && (
    <Button
      floating
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        right: 50,
        bottom: 50,
        padding: 0,
      }}
      iconClassName={"fa fa-arrow-up fa-2x"}
      onClick={() =>
        scroll.scrollToTop({
          duration: 1000,
          smooth: true,
          containerId: "contentContainer",
        })
      }
    />
  )
}
      </div >
    );
  }
}

const styles = {};
