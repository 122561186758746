import React from "react";
import {} from "react-md";

import { isBrowser, isMobile } from "react-device-detect";

// Change the submodule again FFF
export default class HeaderRight extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    return (
      <div
        style={{
          ...this.props.style,
          ...{
            flex: 1,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center"
          }
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

const styles = {
};
