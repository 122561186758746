import HttpService from './HttpService';
const config = require("../config/Config");


export default class PaymentService {

    static createPaymentOrder(data){
        return new Promise((resolve, reject) => {
            HttpService.post(`${HttpService.apiURL()}/payment/execute/transaction`, data, function(data) {
                resolve(data);
            }, function(textStatus) {
                reject(textStatus);
            });
 
        });
     }

     static executePayment(data){
        return new Promise((resolve, reject) => {
            HttpService.post(`${HttpService.apiURL()}/payment/execute`, data, function(data) {
                resolve(data);
            }, function(textStatus) {
                reject(textStatus);
            });
 
        });
     }

     static createPayment(data){
        return new Promise((resolve, reject) => {
            HttpService.post(`${HttpService.apiURL()}/payment/create`, data, function(data) {
                resolve(data);
            }, function(textStatus) {
                reject(textStatus);
            });
 
        });
     }

    static checkTransaction(data){
       return new Promise((resolve, reject) => {
           HttpService.post(`${HttpService.apiURL()}/payment/check/transaction`, data, function(data) {
               resolve(data);
           }, function(textStatus) {
               reject(textStatus);
           });

       });
    }

}