import React from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
import PaymentService from "../services/PaymentService";
import Subtitle from "./Subtitle";
import GridLoader from "react-spinners/GridLoader";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const CLIENT = {
  sandbox: "ASFVwtILEjGPpcQ8XOYAsUvJ6vWx-Pq_5mVFrrvcu9hu31ovN0KewpIyIGrZpMUpr9RzK20LNW7pdbcY",
  production: "AVRwBvaqh4rqr2Yj6W7vTv9jLacQVdqHuw5EGMP8CzlKEf828RMi7WOCoSFDIbC0TsWgOvoYJ4MH_FWp",
};

const CLIENT_ID = process.env.NODE_ENV === "dev" ? CLIENT.sandbox : CLIENT.production;
// const CLIENT_ID = CLIENT.production // CLIENT.sandbox;
// process.env.NODE_ENV === "production" ? CLIENT.production : CLIENT.sandbox;

let PayPalButton = null;
class PaypalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showButtons: false,
      loading: false,
      paid: false,
    };

    this.paypalActions = undefined;

    window.React = React;
    window.ReactDOM = ReactDOM;

    this.createOrder = this.createOrder.bind(this);
    this.onApprove = this.onApprove.bind(this);
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
      this.setState({ loading: false, showButtons: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const scriptJustLoaded = !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PayPalButton = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM,
        });
        this.setState({ loading: false, showButtons: true });
      }
    }
  }

  /**
   * This function is executed as soon as the PayPal button is hitted.
   * @param {object} data
   * @param {*} actions
   * @returns
   */
  createOrder = (data, actions) => {
    var ctxt = this;
    this.setState({ ...ctxt.state, ...{ loading: true } });
    // Create order server sided, i.e. send the request to the server.
    // return this.props.send({ payment: "paypal", action: actions }).then(function (res) {
    //   // this.setState({ ...ctxt.state, ...{ loading: true } });
    //   return res;
    // });
    return this.props.send({ payment: "paypal", action: actions })
  };

  /**
   * Function that is executeded as soon as the payment is done.
   * @param {*} data
   * @param {*} actions
   */
  onApprove = (data, actions) => {
    console.log(data)

    // Executed when order is done/fulfilled
    actions.order.capture().then((details) => {
      PaymentService.checkTransaction({ id: data.orderID, payerId: data.payerID })
        .then((data) => {
          //paypal verify

          var ctxt = this;

          this.props.send({ payment: "paypal verified", action: actions }).then(function (res) {
            // Display that order is paid
            ctxt.setState({ ...ctxt.state, ...{ showButtons: false} });

            return res;
          });

          // this.props.confirmOrder();
        })
        .catch((data) => {
          this.props.confirmOrder(data.message ? data.message : data, true);
        });
    });
  };

  onCancel = (data, actions) => {
    // Executed when order is done/fulfilled
    // this.props.confirmOrder(data.message ? data.message : data, true);
    this.setState({ ...this.state, ...{ loading: false } });
  };

  render() {
    const { showButtons, loading, paid } = this.state;  

    return (
      <div style={{ position: "relative" }}>
        {this.state.loading && (
          <div
            style={{
              position: "absolute",
              zIndex: 1000,
              backgroundColor: "#ffffffcc",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <GridLoader
              css={"margin: auto;"}
              sizeUnit={"px"}
              size={20}
              color={"rgb(255, 79, 41)"}
              loading={true}
            />
            <Subtitle title={"Warte auf Bestätigung der Zahlung."} textStyle={{ color: "black", textAlign: "center"}} />
          </div>
        )}
        {!this.props.disabled && showButtons && (
          <PayPalButton
            createOrder={(data, actions) => this.createOrder(data, actions)}
            onApprove={(data, actions) => this.onApprove(data, actions)}
            onCancel={(data, actions) => this.onCancel(data, actions)}
          />
        )}
        {!showButtons && (
          <GridLoader
            css={"margin: auto;"}
            sizeUnit={"px"}
            size={20}
            color={"rgb(255, 79, 41)"}
            loading={true}
          />
        )}
        
      </div>
    );
  }
}

export default scriptLoader(
  `https://www.paypal.com/sdk/js?currency=EUR&client-id=${CLIENT_ID}&disable-funding=credit,card,sepa,giropay,sofort`,
  "https://www.paypalobjects.com/api/checkout.js"
)(PaypalButton);
