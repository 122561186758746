import React from "react";

import { withRouter } from "react-router-dom";
import { List, ListItem } from "@react-md/list";
import { Button } from "@react-md/button";
import { TextField, Checkbox } from "@react-md/form";

class BestellFormular extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      telError: false,
      telErrorMsg: "",
      nameError: false,
      nameErrorMsg: "",
      adrError: false,
      adrErrorMsg: "",
      emailError: false,
      emailErrorMsg: "",
      stadtError: false,
      stadtErrorMsg: ""
    };
  }

  render() {
    return (
      <div style={{ padding: "15px" }}>
        <TextField
          id="name"
          label="Name"
          placeholder="Name"
          //   className="md-cell md-cell--top"
          //   helpText="Look at me. I am always here!"
          error={this.props.nameErrMsg!=""}
          errorText={this.props.nameErrMsg}
          onChange={evt => this.props.setName(evt.target.value)}
          style={{marginBottom:10}}
        />
        {this.props.delivery != false && (
          <div>
            <TextField
              id="adresse"
              label="Adresse"
              placeholder="Strasse / Haus Nr."
              //   className="md-cell md-cell--top"
              helpText="Bsp.: Musterstraße 11, rechter Eingang"
              error={this.props.addrErrMsg != ""}
              errorText={this.props.addrErrMsg}
              onChange={evt => this.props.setAddress(evt.target.value)}
              style={{marginBottom:10}}

            />
            <TextField
              id="ort"
              label="Stadt/Ort"
              placeholder="Stadt/Ort"
              //   className="md-cell md-cell--top"
              helpText="Bsp.: Polsum"
              error={this.props.stadtErrMsg != ""}
              errorText={this.props.stadtErrMsg}
              onChange={evt => this.props.setStadt(evt.target.value)}
              style={{marginBottom:10}}
            />
            <TextField
              id="plz"
              label="PLZ"
              placeholder="PLZ"
              //   className="md-cell md-cell--top"
              helpText="Bsp.: 45768"
              onChange={evt => this.props.setPlz(evt.target.value)}
              style={{marginBottom:10}}
            />

            <TextField
              id="email"
              label="Email"
              placeholder="Email"
              error={this.props.emailErrMsg != ""}
              errorText={this.props.emailErrMsg}
              helpText="Optional: Um Bestellbestätigung zu erhalten."
              onChange={evt => this.props.setEmail(evt.target.value)}
              style={{marginBottom:10}}
            />
            
          </div>
        )}

        <TextField
          id="telefon"
          label="Telefon"
          placeholder="Telefon"
          //   className="md-cell md-cell--top"
          //    helpText="Bsp.: 45768 Marl-Polsum"
          error={this.props.telErrMsg != ""}
          errorText={this.props.telErrMsg}
          onChange={evt => this.props.setTel(evt.target.value)}
          style={{marginBottom:10}}
        />

        <Checkbox
          // style={{display: "inline"}}
          id="Datenschutzerklärung"
          name="simple-checkboxes[]"
          onChange={this.props.setDataprivacy}
          label={
            <div style={{fontFamily: "'Dosis', sans-serif", color: this.props.dataprivacyErrMsg != "" ? "#f44336" : ""}}>
              Ich habe die <a href="/Datenschutz">Datenschutzerklärung</a> gelesen und
              zur Kenntnis genommen.
            </div>
          }
        />
        {this.props.dataprivacyErrMsg != "" &&
        <div style={{fontFamily: "'Dosis', sans-serif", color: "#f44336", fontWeight: 700}}>
        {this.props.dataprivacyErrMsg}
      </div>

        }
      </div>
    );
  }
}

export default withRouter(BestellFormular);

const styles = {};
