import React from "react";
import { withRouter } from "react-router-dom";
import { isBrowser, isMobile } from "react-device-detect";
import HeaderLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import { MenuSVGIcon } from "@react-md/material-icons";
import { Button } from "@react-md/button";
import { AppBar} from "@react-md/app-bar";

import { SocialIcon } from "react-social-icons";

// const cfg = require("../Config");

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    return (
      <AppBar
        style={{
          ...this.props.style,
          ...{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100vw",
            backgroundColor: "#FF3A07",
            alignItems: "unset"
          }
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center"
          }}
        >

          {isMobile &&  this.props.openMenu !== undefined && (
      
            <Button
                  buttonType="icon"
                  onClick={() => this.props.openMenu()}
                ><MenuSVGIcon/></Button>
          )}

          {this.props.children != undefined && [this.props.children].find(e => e.type === HeaderLeft) != undefined && (
            <div style={{ ...styles.contentContainer }}>
              {[this.props.children].find(e => e.type === HeaderLeft)}
              {process.env.REACT_APP_LOCAL &&
              <div>
                TEST
              </div>
            }
            </div>
            
          )}
          <h1
            style={{
              ...styles.title,
              ...{
                fontSize: isMobile ? "2.5em" : undefined,
                lineHeight: isMobile ? "1em" : undefined
              }
            }}
          >
            {isMobile ? this.props.titleMobile : this.props.title}
          </h1>
        </div>

        <img
          style={{ ...{ width: isMobile ? "70px" : "100px", height: isMobile ? "70px" :  "100px", zIndex: 99999 } }}
          src={this.props.titleLogo}
          alt="Logo"
        />

        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center"
          }}
        >
          <h2
            style={{
              ...styles.subTitle,
              ...{
                fontSize: isMobile ? "2.5em" : undefined,
                lineHeight: isMobile ? "1em" : undefined
              }
            }}
          >
            {isMobile ? this.props.subTitleMobile : this.props.subTitle.address}
          </h2>

          {!isMobile &&
            <h2 style={styles.subTitle}>
                Tel.:
                <a href={this.props.subTitle.telAttr}>
                  {this.props.subTitle.tel}
                </a>
              </h2>
          }

          {this.props.socialMedia != false && isBrowser && (
            <div>
              <SocialIcon
                network="facebook"
                style={{ height: 40, width: 40, marginLeft: 10 }}
              />
              <SocialIcon
                network="instagram"
                style={{ height: 40, width: 40, marginLeft: 10 }}
              />
              <SocialIcon
                network="email"
                style={{ height: 40, width: 40, marginLeft: 10 }}
              />
            </div>
          )}

          {this.props.children != undefined && [this.props.children].find(e => e.type === HeaderRight) != undefined && (
            <div style={{ ...styles.contentContainer }}>
              {[this.props.children].find(e => e.type === HeaderRight)}
            </div>
          )}

        </div>
      </AppBar>
    );
  }
}

export default withRouter(Header);

const styles = {
  contentContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    marginRight: isMobile ? "10px" : "50px"
  },
  button: {
    backgroundColor: "#FF4F29",
    color: "white",
    margin: 5
  },
  headerStyle: {
    webkitBboxShadow: " 0px 8px 15px -14px black",
    mozBoxShadow: " 0px 8px 15px -14px black",
    boxShadow: " 0px 8px 15px -14px black"
  },
  title: {
    textAlign: "center",
    width: "100%",
    margin: "0",
    fontFamily: "Mr De Haviland",
    color:" #FFF503",
    fontWeight: 700,
    letterSpacing: "0.1em",
    fontSize: "3em",
  },
  subTitle: {
    color: "white",
    marginLeft: "10px",
    fontFamily: "'Dosis', serif",
    marginBottom: "0px !important"
  }
};
