import React, { Component } from "react";

export default class FacebookLarge extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ ...this.props.style }}>
        <div
        style={{height: "100%", minHeight: "500px"}}
          class="fb-page fb_iframe_widget"
          data-href="https://www.facebook.com/Pizzeria-Roma-Marl-Polsum-120282051363696/"
          data-tabs="timeline"
          data-width="500"
          data-small-header="false"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
          fb-xfbml-state="rendered"
          fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=&amp;container_width=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FPizzeria-Roma-Marl-Polsum-120282051363696%2F&amp;locale=de_DE&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=500"
        >
          <span style={{verticalAlign: "bottom", width: "95%", height: "100%"}}>
            <iframe
              name="f24d89522de023c"
              width= "100%" //"500px"
              height="100%"
              // frameborder="0"
              allowtransparency="true"
              allowFullScreen={true}
              scrolling="no"
              allow="encrypted-media"
              title="fb:page Facebook Social Plugin"
              src="https://www.facebook.com/v3.2/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fconnect%2Fxd_arbiter%2Fr%2Fd_vbiawPdxB.js%3Fversion%3D44%23cb%3Df2d0b7402a8d4cc%26domain%3Dpizzeriaroma-polsum.de%26origin%3Dhttps%253A%252F%252Fpizzeriaroma-polsum.de%252Ff12119ca8d06f34%26relation%3Dparent.parent&amp;container_width=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FPizzeria-Roma-Marl-Polsum-120282051363696%2F&amp;locale=de_DE&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=500"
              style= {{border: "none", visibility: "visible", width: "95%", maxHeight: "100%"}}
              className=""
            />
          </span>
        </div>
      </div>
    );
  }
}

const styles = {
  menuHeadContainer: {}
};
