import React from "react";
import { TextField } from "@react-md/form";
import { Divider } from "@react-md/divider";
import { Button } from "@react-md/button";
import { Card, CardContent, CardActions } from "@react-md/card";
import { List, ListItem } from "@react-md/list";
import { Collapse } from "@react-md/transition";
import { IconRotator } from "@react-md/icon";
import { KeyboardArrowDownSVGIcon, DeleteForeverSVGIcon } from "@react-md/material-icons";

import { withRouter } from "react-router-dom";
import Title from "./Title";

import { isMobile } from "react-device-detect";
import DishCardSelection from "./DishCardSelection";

/**
 *
 */
class DishCard extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            priceSelectionIsOpen: false,
            extras: [],
            minus: [],
            notice: "",
            selectedValue:
                this.props.price.find((x) => {
                    return x.auswahl === this.props.selection || x.auswahl === "";
                }) === undefined
                    ? this.props.price[0]
                    : this.props.price.find((x) => {
                        return x.auswahl === this.props.selection || x.auswahl === "";
                    }),
            price:
                this.props.price.find((x) => {
                    return x.auswahl === this.props.selection || x.auswahl === "";
                }) === undefined
                    ? this.props.price[0].preis
                    : this.props.price.find((x) => {
                        return x.auswahl === this.props.selection || x.auswahl === "";
                    }).preis,
            prices: this.props.price,
            selection: this.props.selection,
            selections: this.props.selections,
            additionalSelection:
                this.props.selections !== undefined
                    ? this.props.selections.reduce((acc, current) => ((acc[current.name] = current.items[0]), acc), {})
                    : {},
            showSlectionDialog: false,
            cardCollappsed: true,
        };

        var selectionOpportunities = {};

        for (var i = 1; i < 3; i++) {
            selectionOpportunities[`key_${i}`] = false;
        }

        this.setState(Object.assign({}, this.state, selectionOpportunities));

        this.changeSelection = this.changeSelection.bind(this);
        this.changePrice = this.changePrice.bind(this);
        this.addExtras = this.addExtras.bind(this);
        this.addMinus = this.addMinus.bind(this);

        this.addToCart = this.addToCart.bind(this);
        this.removeExtra = this.removeExtra.bind(this);

        this.openSelection = this.openSelection.bind(this);

        this.isItemChecked = this.isItemChecked.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.price !== state.prices) {
            // return the new state object
            return {
                extras: [],
                minus: [],
                notice: "",
                selectedValue:
                    props.price.find((x) => {
                        return x.auswahl === props.selection || x.auswahl === "";
                    }) === undefined
                        ? props.price[0]
                        : props.price.find((x) => {
                            return x.auswahl === props.selection || x.auswahl === "";
                        }),
                price:
                    props.price.find((x) => {
                        return x.auswahl === props.selection || x.auswahl === "";
                    }) === undefined
                        ? props.price[0].preis
                        : props.price.find((x) => {
                            return x.auswahl === props.selection || x.auswahl === "";
                        }).preis,
                prices: props.price,
                selection: props.selection,
                selections: props.selections,
                additionalSelection:
                    props.selections !== undefined
                        ? props.selections.reduce((acc, current) => ((acc[current.name] = current.items[0]), acc), {})
                        : {},
                additionalPrices: [],
            };
        }
        return null;
    }

    /**
     * 
     * @param {*} name 
     * @param {*} price 
     * @param {*} origin 
     */
    changeSelection(name = undefined, price = undefined, origin) {
        var state = this.state;
        var elem = name;
        var elemPrice = parseFloat(price);

        state.selection = `${name} ${price}`;

        if (origin === "price") {

            // Update extra prices, if the selction changed
            state.extras = state.extras.map(e => { return { 
                name: e.name, 
                preis: this.props.extras.find(x => x.name === e.name).preis[name] || this.props.extras.find(x => x.name === e.name).preis["Default"] , 
                berechnet: this.props.extras.find(x => x.name === e.name).preis[name] || this.props.extras.find(x => x.name === e.name).preis["Default"] } })

            state.price = (
                parseFloat(elemPrice) +
                Object.values(state.additionalSelection).reduce((acc, curr) => acc + parseFloat(curr.preis), 0) +
                state.extras.reduce((acc, curr) => acc + parseFloat(curr.berechnet), 0) -
                state.minus.reduce((acc, curr) => acc + parseFloat(curr.berechnet), 0)
            ).toFixed(2);

            state.selectedValue = { auswahl: elem, preis: elemPrice };
        } else {
            state.additionalSelection[origin] = { name: elem, preis: elemPrice };

            state.price = (
                state.selectedValue.preis +
                Object.values(state.additionalSelection).reduce((acc, curr) => acc + parseFloat(curr.preis), 0) +
                state.extras.reduce((acc, curr) => acc + parseFloat(curr.berechnet), 0) -
                state.minus.reduce((acc, curr) => acc + parseFloat(curr.berechnet), 0)
            ).toFixed(2);
        }

        this.setState(state);
    }

    changePrice(price) {
        var state = this.state;
        state.price = price;
        this.setState(state);
    }

    addMinus(elem) {
        var state = this.state;
        var elemPrice = parseFloat(elem.split(" ")[elem.split(" ").length - 1].replace("€", ""));
        elem = elem.split(" ").slice(0, -1).join(" ");

        var minObj = { name: elem, preis: elemPrice, berechnet: elemPrice };
        var extraSum = state.extras.reduce((acc, curr) => acc + parseFloat(curr.berechnet), 0);

        var minSum = state.minus.reduce((acc, curr) => acc + parseFloat(curr.berechnet), 0);

        var idx = state.minus.findIndex((x) => x.name === elem);

        if (idx > -1) {
            // Element is already "deleted"

            // Price we reduced
            var red = elemPrice;

            // Distribute the reduced value over all extras with preis != 0 and berechnet 0
            state.extras.map((x) => {
                if (x.berechnet < x.preis && x.preis != 0) {
                    var old = x.berechnet;
                    x.berechnet = x.berechnet + red > x.preis ? x.preis : x.berechnet + red;
                    red = red - (x.berechnet - old) < 0 ? 0 : red - (x.berechnet - old);
                }
            });

            state.minus.splice(idx, 1);
        } else {
            // We have to add the new minus element
            // Steps to do: Add to minus array

            // minObj = {name: elem, preis: elemPrice, berechnet: elemPrice}

            if (extraSum - minSum - elemPrice < 0) {
                // elemPrice = 0;
                minObj = { name: elem, preis: elemPrice, berechnet: 0 };
            }

            if (this.props.filter.disabledMinus) {
                minObj.berechnet = 0;
                minObj.preis = 0;
            }

            state.minus.push(minObj);
        }

        minSum = state.minus.reduce((acc, curr) => acc + parseFloat(curr.berechnet), 0);
        extraSum = state.extras.reduce((acc, curr) => acc + parseFloat(curr.berechnet), 0);

        var additionalPricesSum = Object.values(state.additionalSelection).reduce(
            (acc, curr) => acc + parseFloat(curr.preis),
            0
        );

        state.price = parseFloat(state.selectedValue.preis) + additionalPricesSum + -minSum + extraSum;
        this.setState(state);
    }

    addExtras(extra) {
        var state = this.state;
        var extraLength = extra.split(" ").length - 1;
        var extraPreis = parseFloat(extra.split(" ")[extraLength].replace("€", ""));
        extra = extra.split(" ").slice(0, extraLength).join(" ");

        var minSum = state.minus.reduce((acc, curr) => acc + parseFloat(curr.preis), 0);
        var extraSum = state.extras.reduce((acc, curr) => acc + parseFloat(curr.preis), 0);

        var extraObj = { name: extra, preis: extraPreis, berechnet: 0 }; // brechnet is the price we will use for the final payment

        if (minSum - (extraSum + extraPreis) < 0) {
            extraObj = {
                name: extra,
                preis: extraPreis,
                berechnet: extraSum + extraPreis - minSum < extraPreis ? extraSum + extraPreis - minSum : extraPreis,
            };
        }

        state.extras.push(extraObj);

        minSum = state.minus.reduce((acc, curr) => acc + parseFloat(curr.berechnet), 0);
        extraSum = state.extras.reduce((acc, curr) => acc + parseFloat(curr.berechnet), 0);

        if (extraSum + extraObj.berechnet - minSum > 0) {
            var additionalPricesSum = Object.values(state.additionalSelection).reduce(
                (acc, curr) => acc + parseFloat(curr.preis),
                0
            );

            state.price = parseFloat(state.selectedValue.preis) + additionalPricesSum + -minSum + extraSum;
        }
        this.setState(state);
    }

    removeExtra(i) {
        var state = this.state;
        var extra = state.extras[i];

        // Delete extra from list
        state.extras.splice(i, 1);

        // Change price
        state.price -= extra.berechnet;

        var minSum = state.minus.reduce((acc, curr) => acc + parseFloat(curr.preis), 0);
        var extraSum = state.extras.reduce((acc, curr) => acc + parseFloat(curr.preis), 0);
        var diff = minSum - extraSum;

        if (diff >= 0 && minSum >= 0) {
            // Update extras list
            state.extras.map((x) => {
                x.berechnet = x.berechnet - minSum > 0 ? x.berechnet - minSum : 0;
                minSum = minSum - x.berechnet > 0 ? minSum - x.berechnet : 0;
            });
        }

        if (diff >= 0 && extraSum >= 0) {
            // Update minus list
            state.minus.map((x) => {
                x.berechnet = extraSum == 0 ? 0 : x.berechnet - extraSum > 0 ? x.berechnet - extraSum : 0;
                extraSum = extraSum - x.berechnet > 0 ? extraSum - x.berechnet : 0;
            });
        }

        extraSum = state.extras.reduce((acc, curr) => acc + parseFloat(curr.berechnet), 0);
        minSum = state.minus.reduce((acc, curr) => acc + parseFloat(curr.berechnet), 0);

        if (extraSum - minSum >= 0) {
            var additionalPricesSum = Object.values(state.additionalSelection).reduce(
                (acc, curr) => acc + parseFloat(curr.preis),
                0
            );
            state.price = parseFloat(state.selectedValue.preis) + additionalPricesSum + extraSum - minSum;
        }

        this.setState(state);
    }

    addToCart() {
        // var additionalSelectionString = Object.values(this.state.additionalSelection).map(x => x.name).join()
        // additionalSelectionString = additionalSelectionString !==  "" ? " ," + additionalSelectionString  : additionalSelectionString

        var order = {
            dishes: [
                {
                    nr: this.props.nr,
                    name: this.props.name,
                    price: this.state.price,
                    ingredients: this.props.ingredients,
                    selection: this.state.selectedValue.auswahl,
                    additionalSelection: this.state.additionalSelection,
                    minus: this.state.minus,
                    extras: this.state.extras,
                    notice: this.state.notice,
                },
            ],
            offer: "-1",
            offerExtra: [],
            price: this.state.price,
        };

        this.props.addToCart(order, this.state.price);

        // Make state default
        this.setState({
            extras: [],
            minus: [],
            notice: "",
            selectedValue:
                this.props.price.find((x) => {
                    return x.auswahl === this.props.selection || x.auswahl === "";
                }) === undefined
                    ? this.props.price[0]
                    : this.props.price.find((x) => {
                        return x.auswahl === this.props.selection || x.auswahl === "";
                    }),
            price:
                this.props.price.find((x) => {
                    return x.auswahl === this.props.selection || x.auswahl === "";
                }) === undefined
                    ? this.props.price[0].preis
                    : this.props.price.find((x) => {
                        return x.auswahl === this.props.selection || x.auswahl === "";
                    }).preis,
            prices: this.props.price,
            selection: this.props.selection,
            selections: this.props.selections,
            additionalSelection:
                this.props.selections !== undefined
                    ? this.props.selections.reduce((acc, current) => ((acc[current.name] = current.items[0]), acc), {})
                    : {},
        });
    }

    openSelection(which) {
        var state = this.state;
        state.showSlectionDialog = true;
        this.setState(state);
    }

    isItemChecked(item, mode = "Zutaten entfernen") {
        if (mode === "Zutaten entfernen") {
            return this.state.minus.some((x) => item.auswahl === x.name)
        } else if (mode === "Extras auswählen") {
            this.state.extras.some((x) => item.auswahl === x.name)
        }
    }

    render() {
        return (
            <div style={styles.container}>
                <Card className="md-block-centered" style={{ width: "100%", position: "relative" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <div style={{ width: "calc(100%)" }}>
                            <Title
                                title={
                                    this.props.nr !== "" ? `Nr. ${this.props.nr} ${this.props.name}` : this.props.name
                                }
                                facebook={false}
                                fontStyle={{
                                    fontWeight: "600",
                                    fontSize: "1.5em",
                                    textAlign: "left",
                                }}
                            />

                            <div style={styles.ingredentContainer}>
                                {this.props.ingredients.map((c, i) => {
                                    if (this.state.minus.length == 0) {
                                        return (
                                            <div id={`ing${i}`} key={`ing${i}`} style={{ marginRight: 5 }}>
                                                {c}
                                            </div>
                                        );
                                    } else {
                                        if (this.state.minus.some((x) => x.name === c)) {
                                            return (
                                                <del id={`ing${i}`} key={`ing${i}`} style={{ marginRight: 5 }}>
                                                    {c}
                                                </del>
                                            );
                                        } else {
                                            return (
                                                <div id={`ing${i}`} key={`ing${i}`} style={{ marginRight: 5 }}>
                                                    {c}
                                                </div>
                                            );
                                        }
                                    }
                                })}
                            </div>

                            <Divider />
                        </div>
                    </div>

                    {/* Auswahl optionen */}
                    <div style={{ ...styles.ddContainer, ...{ marginTop: 10 } }}>
                        {/* Entfernen */}
                        <DishCardSelection
                            title={"Zutaten entfernen."}
                            disabled={
                                (!this.props.extras ||
                                    this.props.extras.filter((x) => this.props.ingredients.includes(x.name)).length <= 0) &&
                                this.props.ingredients.length <= 0
                            }
                            changeSelection={(elem, preis) => {
                                this.addMinus(elem + " " + preis);
                            }}
                            text={"Zutaten entfernen"}
                            selected={this.state.minus.map((x) => x.name + " " + x.preis.toFixed(2)).join()}
                            updateState={(x) => { }}
                            prices={
                                !this.props.extras
                                    ? []
                                    : this.props.ingredients.map(x => { return { auswahl: x, preis: 0.0 } })
                                // : this.props.extras
                                //       .filter((x) => this.props.ingredients.includes(x.name))
                                //       .concat(this.props.ingredients.map(x => {return { name: x}}))
                                //       .map((x) => {
                                //           return { auswahl: x.name, preis: 0.0 };
                                //       })
                            }
                            isItemChecked={this.isItemChecked}
                        />

                        {/* Price/Option */}
                        <DishCardSelection
                            title={"Bitte wählen Sie eine Option aus."}
                            disabled={this.props.filter.filterSize && this.props.filter.filterSize.length <= 1}
                            singleSelection={true}
                            changeSelection={this.changeSelection}
                            text={this.state.selectedValue}
                            selected={`${this.state.selectedValue.auswahl} ${this.state.selectedValue.preis.toFixed(
                                2
                            )}`}
                            updateState={(x) => { }}
                            prices={!this.state.prices ? [] : this.state.prices.filter((x) => x.preis !== -1)}
                            isItemChecked={this.isItemChecked}
                        />

                        {/* {this.state.selections != undefined &&
                            this.state.selections.length > 0 &&
                            this.state.selections.map((s, i) => (
                                <div>
                                    <div style={{ minHeight: isMobile ? 0 : 19 }}>{s.name}</div>
                                    <Select
                                        key={i}
                                        id="additionalSelections"
                                        fullWidth={true}
                                        inputStyle={{
                                            fontFamily: "'Dosis', sans-serif",
                                            width: "180px",
                                            fontSize: "1.5em",
                                        }}
                                        listStyle={{
                                            color: "unset",
                                            fontFamily: "'Dosis', sans-serif",
                                        }}
                                        simplifiedMenu={true}
                                        onChange={(elem, index, event, details) => {
                                            var lastIndex = elem.split(" ").length - 1;
                                            this.changeSelection(
                                                elem.split(" ").splice(0, lastIndex),
                                                elem.split(" ")[lastIndex],
                                                s.name
                                            );
                                        }}
                                        menuItems={
                                            s.items
                                                ? s.items.map(
                                                      (x) => x.name + " " + parseFloat(x.preis).toFixed(2) + "€"
                                                  )
                                                : []
                                        }
                                        disabled={
                                            this.props.filter.filterSize && this.props.filter.filterSize.length <= 1
                                        }
                                        position={Select.Positions.BELOW}
                                        value={
                                            this.state.additionalSelection[s.name].name +
                                            " " +
                                            this.state.additionalSelection[s.name].preis.toFixed(2) +
                                            "€"
                                        }
                                    />
                                </div>
                            ))} */}
                    </div>

                    <CardActions>
                        <Button
                            id="expand-card-button"
                            onClick={() => {
                                this.setState({ ...this.state, ...{ cardCollappsed: !this.state.cardCollappsed } });
                            }}
                            buttonType="icon"
                            aria-label="Expand"
                            theme="clear"
                        >
                            <IconRotator rotated={!this.state.cardCollappsed}>
                                <KeyboardArrowDownSVGIcon />
                            </IconRotator>
                        </Button>
                    </CardActions>

                    <Collapse collapsed={this.state.cardCollappsed}>
                        <CardContent>
                            <div style={styles.extrasList}>
                                {/* Extras */}
                                <DishCardSelection
                                    title={"Extras auswählen."}
                                    disabled={!this.props.extras}
                                    changeSelection={(elem, preis = -1, event = "add") => {
                                        if (event === "add") {
                                            this.addExtras(elem + " " + preis);
                                        } else if (event == "remove") {
                                            var idx = this.state.extras.findIndex(
                                                (x) => (x.name == elem) & (x.preis == preis)
                                            );
                                            this.removeExtra(idx);
                                        }
                                    }}
                                    text={"Extras auswählen"}
                                    selected={this.state.extras}
                                    prices={this.props.extras.map((e) => {
                                        var size = this.state.selection.split(" ")[0]; // Größe
                                        return {
                                            auswahl: e.name,
                                            preis: e.preis[size] ? e.preis[size] : e.preis["Default"],
                                        };
                                    })}
                                    isItemChecked={this.isItemChecked}
                                />
                                <List
                                    style={{ width: isMobile ? "100%" : "90%", minHeight: 50 }}
                                    className="md-cell md-paper md-paper--1"
                                >
                                    {/* <div style={{ marginLeft: 10 }}>Extras:</div> */}


                                    {this.state.extras.map((c, i) => (
                                        <ListItem
                                            leftAddon={
                                                <Button buttonType="icon" onClick={() => this.removeExtra(i)}>
                                                    <DeleteForeverSVGIcon />
                                                </Button>
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    color: "black",
                                                }}
                                            >
                                                <div>{c.name}</div>
                                                <div>{c.preis.toFixed(2) + "€"}</div>
                                            </div>
                                        </ListItem>
                                    ))}
                                </List>


                            </div>

                            <TextField
                                id="anmerkung"
                                FFB69B
                                style={styles.noticeField}
                                placeholder="Anmerkung"
                                helpText='Bitte beachten Sie, dass zusätzliche Extras oder Beilagen in der Anmerkung möglicherweise nachträglich entsprechend berechnet werden. Für Pizzabrötchen schauen Sie in der Kategorie "Sonstiges".'
                                rows={1}
                                onChange={(evt) => this.setState(Object.assign({}, this.state, { notice: evt.target.value }))}
                            />
                        </CardContent>
                    </Collapse>

                    <Button
                        raised
                        style={{
                            backgroundColor: "rgba(254, 215, 112, 1)",
                            color: "black",
                            margin: 10,
                            width: "calc(100% - 20px)",
                            fontSize: 16,
                            height: "auto",
                        }}
                        onClick={() => this.addToCart()}
                        iconClassName={"fa fa-cart-plus fa-lg"}
                    >
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <div>
                                {`${this.props.name}, ${this.state.selectedValue.auswahl} ${parseFloat(this.state.price).toFixed(2) != undefined
                                    ? parseFloat(this.state.price).toFixed(2)
                                    : this.state.price
                                    }€`}
                            </div>
                        </div>
                    </Button>
                </Card>
            </div>
        );
    }
}

export default withRouter(DishCard);

const styles = {
    ddContainer: {
        //width: "60%",
        display: "flex",
        // alignItems: "center",
        justifyContent: "space-around",
        flexWrap: "wrap",
        paddingLeft: 20,
        paddingRight: 20,
    },
    price: {
        textAlign: "center",
        fontSize: "1em",
        marginTop: "20px",
        fontFamily: "'Dosis', sans-serif",
    },
    cropBox: {
        float: "right",
        fontSize: "2em",
        width: "150px",
        height: "100%",
        overflow: "hidden",
    },
    priceBG: {
        borderRadius: "50%",
        backgroundColor: "#FED770",
        marginTop: "-150px",
        width: "200px",
        height: "200px",
    },
    container: {
        paddingLeft: "20px",
        paddingRight: "20px",
        marginTop: "20px",
        minHeight: 127,
    },
    footer: {
        backgroundColor: "#FF3A07",
        width: "100%",
        minHeight: "100px",
    },
    extrasList: {
        // display: "flex",
        // flexDirection: isMobile ? "column" : "row",
        // alignItems: "center",
        // justifyContent: "space-around",
        width: "100%",
    },
    noticeField: {
        width: "calc(100% - 20px)",
        marginLeft: "10px",
        marginRight: "10px",
        color: "black !important",
    },
    ingredentContainer: {
        display: "flex",
        fontFamily: "'Dosis', serif",
        fontWeight: "400",
        fontSize: 14,
        minHeight: 28,
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingTop: "10px",
        flexWrap: "wrap",
    },
};
