import React from "react";

import { withRouter } from "react-router-dom";
import { Card, CardContent, CardTitle } from "@react-md/card";
import { Dialog } from "@react-md/dialog";
import { Button } from "@react-md/button";
import { Divider } from "@react-md/divider";
import { Text } from "@react-md/typography";

import { IconRotator } from "@react-md/icon";
import { KeyboardArrowDownSVGIcon } from "@react-md/material-icons";


import { isMobile } from "react-device-detect";

import "../style/Sidebar.css";
import Offerings from "./Offerings";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck } from "@fortawesome/free-solid-svg-icons";

import deliveryTruck from "../res/img/icons/delivery-truck.svg";
import walker from "../res/img/icons/walker.svg";

import Title from "./Title";
import BestellListe from "./BestellListe";
import rubbish from "../res/img/rubbish-bin.svg";

import Responsive from "react-responsive";
import Subtitle from "./Subtitle";
import BestellFormular from "./BestellFormular";
import OrderService from "../services/OrderService";
import FeedbackService from "../services/FeedbackService";
import PaypalButton from "./PaypalButton";
import PaymentService from "../services/PaymentService";
import HttpService from "../services/HttpService";
// const Desktop = (props) => <Responsive {...props} minWidth={992} />;
// const Tablet = (props) => <Responsive {...props} minWidth={768} maxWidth={991} />;
// const Mobile = (props) => <Responsive {...props} maxWidth={767} />;
// const Default = (props) => <Responsive {...props} minWidth={768} />;

const config = require("../config/Config");

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      cart: this.props.cart,
      delivery: true,
      open: this.props.isOpen(),
      customer: {
        name: "",
        address: "",
        tel: "",
        email: "",
        plz: "",
        stadt: "",
      },
      dataprivacyChecked: false,
      telErrMsg: "",
      nameErrMsg: "",
      addrErrMsg: "",
      emailErrMsg: "",
      stadtErrMsg: "",
      dataprivacyErrMsg: "",
      sendSuccessVisible: false,
      responseMsg: "",
      responseError: true,
      paymentsVisible: false,
      showOrderCheckUp: false
    };

    this.updateCart = this.updateCart.bind(this);
    this.sidebarMobile = this.sidebarMobile.bind(this);
    this.send = this.send.bind(this);
    this.sendFeedback = this.sendFeedback.bind(this);

    this.setName = this.setName.bind(this);
    this.setAddress = this.setAddress.bind(this);
    this.setTel = this.setTel.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setStadt = this.setStadt.bind(this);
    this.setPlz = this.setPlz.bind(this);
    this.setDataprivacy = this.setDataprivacy.bind(this);
    this.validateUserForm = this.validateUserForm.bind(this);
    this.confirmOrder = this.confirmOrder.bind(this);

    this.hidePayments = this.hidePayments.bind(this);
    this.showPayments = this.showPayments.bind(this);

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  show = () => {
    this.setState(Object.assign({}, this.state, { sendSuccessVisible: true }));
  };

  hide = () => {
    this.setState(Object.assign({}, this.state, { sendSuccessVisible: false }));
  };

  setName(name) {
    var state = this.state;
    state.customer.name = name;
    this.setState(Object.assign({}, this.state, state));
  }

  setAddress(adr) {
    var state = this.state;
    state.customer.address = adr;
    this.setState(Object.assign({}, this.state, state));
  }

  setTel(tel) {
    var state = this.state;
    state.customer.tel = tel;
    this.setState(Object.assign({}, this.state, state));
  }

  setEmail(email) {
    var state = this.state;
    state.customer.email = email;
    this.setState(Object.assign({}, this.state, state));
  }

  setPlz(plz) {
    var state = this.state;
    state.customer.plz = plz;
    this.setState(Object.assign({}, this.state, state));
  }

  setStadt(stadt) {
    var state = this.state;
    state.customer.stadt = stadt;
    this.setState(Object.assign({}, this.state, state));
  }

  setDataprivacy() {
    this.setState(
      Object.assign({}, this.state, {
        dataprivacyChecked: !this.state.dataprivacyChecked,
      })
    );
  }

  updateCart(i, price) {
    this.cart.extras.splice(i, 1);
    this.cart.orderPrice -= price;
    this.props.updateCart(this.cart);
  }

  sidebarMobile = () => {
    return {
      position: "absolute",
      top: 90,
      left: 0,
      marginLeft: "5%",
      width: "90%",
      height: this.props.showSidebar ? "80vh" : "0px",
      transition: "height 1s",
      overflow: "hidden",
      zIndex: 1000,
      // position: "relative",
    };
  };

  sendFeedback(error) {
    var feedback = {
      category: "Technischer Fehler (Automatisch)",
      origin: "Fehler beim Versenden. (Automatisch)",
      timeOfError: "",
      device: "",
      browser: "",
      feedback: error,
      timestamp: new Date(),
    };

    FeedbackService.addFeedback(feedback)
      .then((data) => {
        this.setState(
          Object.assign({}, this.state, {
            sendSuccessVisible: true,
            responseError: false,
            responseMsg: data.message,
          })
        );
      })
      .catch((data) => {
        this.setState(
          Object.assign({}, this.state, {
            sendSuccessVisible: true,
            responseError: true,
            responseMsg: data.message ? data.message : data,
          })
        );
      });
  }

  showPayments() {
    var update = this.validateUserForm();

    if (update) {
      return;
    } else {
      this.setState({
        ...this.state,
        ...{ paymentsVisible: true },
      });
    }
  }

  hidePayments() {
    this.setState({
      ...this.state,
      ...{ paymentsVisible: false },
    });
  }

  validateUserForm() {
    // Check inputs
    var state = this.state;
    var update = false;

    if (this.state.customer.name === "" || this.state.customer.name == undefined) {
      state.nameErrMsg = "Bitte einen Namen angeben.";
      update = true;
    } else {
      state.nameErrMsg = "";
    }

    if (
      this.state.customer.tel === "" ||
      this.state.customer.tel == undefined ||
      !this.state.customer.tel.match(/^[0-9]*$/gm)
    ) {
      state.telErrMsg = "Bitte eine korrekte Telefonnummer angeben (Nur Zahlen).";
      update = true;
    } else {
      state.telErrMsg = "";
    }

    if (
      this.state.customer.email !== "" &&
      (this.state.customer.email === undefined || !this.state.customer.email.match(/\S+@\S+\.\S+/gm))
    ) {
      state.emailErrMsg = "Bitte eine korrekte Email angeben.";
      update = true;
    } else {
      state.emailErrMsg = "";
    }

    if ((this.state.customer.address === "" || this.state.customer.address == undefined) && this.state.delivery) {
      state.addrErrMsg = "Bitte eine Adresse angeben.";
      update = true;
    } else {
      state.addrErrMsg = "";
    }

    if ((this.state.customer.stadt === "" || this.state.customer.stadt == undefined) && this.state.delivery) {
      state.stadtErrMsg = "Bitte eine Stadt/ein Ort angeben.";
      update = true;
    } else {
      state.stadtErrMsg = "";
    }

    if (!this.state.dataprivacyChecked || (this.state.dataprivacyChecked == undefined && this.state.delivery)) {
      state.dataprivacyErrMsg =
        "Bitte bestätigen Sie, dass Sie die Datenschutzerklärung gelesen und zur Kenntniss genommen haben.";
      update = true;
    } else {
      state.dataprivacyErrMsg = "";
    }

    this.setState(state);
    return update;
  }

  confirmOrder(data = { message: "" }, error = false) {
    this.setState(
      Object.assign({}, this.state, {
        paymentsVisible: false,
        sendSuccessVisible: true,
        responseError: error,
        responseMsg: data.message,
      })
    );
  }

  send = ({ timestamp = new Date(), payment = "cash", action = undefined, orderID = undefined }) => {
    var update = this.validateUserForm();

    if (update) {
      // Error occured
      return;
    }

    var order = {
      cart: this.state.cart,
      customer: this.state.customer,
      delivery: this.state.delivery,
      status: 0,
      timestamp: timestamp,
      printed: 0,
      confirmationSent: 0,
      origin: isMobile ? "mobile" : "Desktop",
      duration: this.state.delivery ? this.props.deliveryTime : this.props.takeawayTime,
      payment: payment,
      paymentState: payment === "cash" ? "cash" : "open",
    };

    if (payment === "paypal") {
      // action.request.post(`${HttpService.apiURL()}/payment/execute/transaction`, order)
      return PaymentService.createPaymentOrder(order)
        .then((data) => {
          return data.id;
        })
        .catch((data) => {
          return data;
        });
    } else if (payment === "paypal verified") {
      if (orderID !== undefined) {
        order._id = orderID
      }
      order.paymentState = "paid"
      // Add order to DB
      OrderService.addOrder(order)
        .then((data) => {
          this.confirmOrder(data);
        })
        .catch((data) => {
          // Show error message
          this.confirmOrder(data.message ? data.message : data, true);
        });
    } else {
      // Add order to DB
      OrderService.addOrder(order)
        .then((data) => {
          this.confirmOrder(data);
        })
        .catch((data) => {
          // Show error message
          this.confirmOrder(data.message ? data.message : data, true);
        });
    }

    // this.props.openSidebar();
    // this.setState(Object.assign({}, this.state, { sendSuccessVisible: true }));
  };

  render() {
    return (
      <div>

        <div
          style={{
            ...{
              height: "80%",
              display: "flex",
              flexDirection: "column",
              paddingBottom: isMobile ? "" : 40,
            },
            ...this.props.style,
          }}
        >
          {this.props.offer != undefined && (
            <Offerings
              open={this.state.open}
              offer={this.props.offer}
              removeOffer={this.props.removeOffer}
              updateCart={this.props.updateCart}
              offerCart={this.props.offerCart}
              addOfferToCart={this.props.addOfferToCart}
            />
          )}

          <Card
            className="md-block-centered sidebar"
            style={{
              overflow: isMobile ? "" : "auto",
              marginBottom: isMobile ? "" : "20px",
              width: isMobile ? "100%" : "",
              height: isMobile ? "" : "",
              padding: 10
            }}
          >
            {console.log(this.state.open)}
            {this.state.open ? (
              <div>
                <CardTitle style={{ display: "block", padding: 10 }} title="">
                  <Subtitle containerStyle={{ paddingLeft: 0 }} title={"Bestellung"} />

                  <div
                    style={{
                      color: this.state.cart.orderPrice < config.minPrice ? "#f93e2e" : "#5fad46",
                      display: this.state.delivery ? undefined : "none",
                    }}
                  >
                    {`Mindest Bestellwert bei Lieferung ${parseFloat(config.minPrice).toFixed(2)} €`}
                  </div>
                </CardTitle>

                <CardContent
                // style={{overflow: "auto", height: isMobile? "calc(100vh - 172px)" :""}}
                >
                  <BestellListe
                    updateCart={this.props.updateCart}
                    cart={this.state.cart}
                    style={{ marginRight: 10, width: "calc(100% - 10px)" }}
                  // offer={this.props.offer}
                  />

                  <Divider />
                  <Subtitle title={this.state.cart.orderPrice.toFixed(2) + "€"} />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: 10,
                    }}
                  >
                    <Button
                      themeType="contained"
                      style={{
                        backgroundColor: this.state.delivery ? "#5FAD46" : "",
                      }}
                      onClick={() => this.setState(Object.assign({}, this.state, { delivery: true }))}
                    >
                      Liefern
                    </Button>
                    <Button
                      themeType="contained"
                      style={{
                        backgroundColor: this.state.delivery ? "" : "#5FAD46",
                      }}
                      onClick={() => this.setState(Object.assign({}, this.state, { delivery: false }))}
                    >
                      Abholen
                    </Button>
                  </div>

                  <BestellFormular
                    delivery={this.state.delivery}
                    setName={this.setName}
                    setAddress={this.setAddress}
                    setTel={this.setTel}
                    setEmail={this.setEmail}
                    setDataprivacy={this.setDataprivacy}
                    setPlz={this.setPlz}
                    setStadt={this.setStadt}
                    telErrMsg={this.state.telErrMsg}
                    emailErrMsg={this.state.emailErrMsg}
                    nameErrMsg={this.state.nameErrMsg}
                    addrErrMsg={this.state.addrErrMsg}
                    stadtErrMsg={this.state.stadtErrMsg}
                    dataprivacyErrMsg={this.state.dataprivacyErrMsg}
                  />

                  <Subtitle containerStyle={{ paddingLeft: 0 }} title={"Bestellung abschließen"} />
                  {this.state.cart.orderPrice < config.minPrice && this.state.delivery && (
                    <p style={{ color: "#f93e2e" }}>{`Lieferung erst ab einen Bestellwert von ${config.minPrice.toFixed(
                      2
                    )} €.`}</p>
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                      justifyContent: "space-around",
                      alignItems: isMobile ? "baseline" : "center",
                      marginBottom: isMobile ? 50 : undefined,
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        themeType="contained"
                        disabled={this.state.cart.orderPrice < config.minPrice && this.state.delivery}
                        onClick={() => this.showPayments()}
                        style={{
                          width: "100%",
                          marginBottom: 10,
                          marginTop: 10,
                          height: "unset",
                          whiteSpace: "nowrap"
                        }}
                      >
                        Zu den Zahlungsarten.
                      </Button>

                      <Divider style={{ width: "100%", marginTop: 5, marginBottom: 5 }} />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ margin: 0 }}>{`Lieferzeit ca. ${this.props.deliveryTime} - ${this.props.deliveryTime + 10
                          } Min.`}</p>
                        <img src={deliveryTruck} style={{ width: 40, height: "auto" }} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ margin: 0 }}>{`Abholzeit ca. ${this.props.takeawayTime} - ${this.props.takeawayTime + 5
                          }Min.`}</p>
                        <img src={walker} style={{ width: 40, height: "auto" }} />
                      </div>
                    </div>
                  </div>
                </CardContent >
              </div>
            ) : (
              <Card
                style={{
                  margin: 10,
                  padding: 10,
                  backgroundColor: "rgba(238, 29, 71, 0.3)",
                  color: "#EE1D47",
                }}
                divider={true}
              >
                <Title title={"Geschlossen. Bitte zu den Öffnungszeiten bestellen."} textStyle={{ color: "#EE1D47" }} />
                <Subtitle title={"Dienstag - Sonntag: 17:00 - 22:30"} textStyle={{ color: "#EE1D47" }} />
              </Card>
            )}
          </Card>
        </div>

        <Dialog
          id="successConfirmation"
          aria-label="successConfirmation"
          disableScrollLocking={true}
          visible={this.state.sendSuccessVisible}
          onRequestClose={() => this.setState({ ...this.state, ...{ sendSuccessVisible: false } })}
          // title={this.state.responseMsg}
          style={{ padding: 15 }}
          contentStyle={{
            backgroundColor: this.state.responseError
              ? "" //"rgba(232, 30, 37, 0.6)"
              : "",
          }}
          dialogStyle={{
            backgroundColor: this.state.responseError ? "rgba(232, 30, 37, 1)" : "",
            padding: 30,
            width: "unset",
          }}
          // onHide={this.hide}
          containFocus={false}
          focusOnMount={false}
        >
          {this.state.responseError ? (
            <div
              style={
                {
                  // padding: 10,
                }
              }
            >
              {this.state.responseError && (
                <Subtitle
                  style={{ padding: 0, paddingBottom: 10 }}
                  title={`Es ist ein Fehler aufgetreten!`}
                  containerStyle={{ paddingLeft: 0, color: "white" }}
                />
              )}

              <p style={{ color: this.state.responseError ? "white" : "" }}>{this.state.responseMsg}</p>

              <p style={{ color: this.state.responseError ? "white" : "" }}>Möchten Sie den Fehler senden?</p>

              <Button
                themeType="contained"
                style={{ color: this.state.responseError ? "white" : "" }}
                onClick={() => this.sendFeedback(this.state.responseMsg)}
              >
                Fehlermeldung senden.
              </Button>
            </div>
          ) : (
            <div>
              <h3>Vielen Dank für Ihre Bestellung!</h3>
              <Divider />
              {this.state.delivery ? (
                <p>Ihre Bestellung wird in ca. {this.props.deliveryTime} Minuten geliefert.</p>
              ) : (
                <p>Ihre Bestellung können Sie in ca. {this.props.takeawayTime} Minuten abholen.</p>
              )}
              {/* <p>
                Ist Ihnen ein Fehler auf der Webseite begegnet oder haben Sie einen Verbesserungsvorschlag? Dann geben
                Sie uns gerne Feedback!
              </p>
              <Button
                raised
                // style={{
                //           backgroundColor: this.state.delivery ? "#5FAD46" : ""
                //         }}
                style={{ width: "100%" }}
                onClick={() => this.props.history.push({ pathname: "Feedback", state: {} })}
              >
                {"Zur Feedback Seite"}
              </Button> */}

              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  theme="error"
                  themeType="contained"
                  onClick={() => this.setState({ ...this.state, ...{ sendSuccessVisible: false } })}
                  style={{ width: "90%", marginBottom: 10, marginTop: 10 }}
                >
                  Schließen
                </Button>
              </div>
            </div>
          )}
        </Dialog>

        {/* Zahlungsarten */}
        <Dialog
          id="paymentDialog"
          aria-label="paymentDialog"
          focusOnMount={false}
          disableScrollLocking={true}
          visible={this.state.paymentsVisible}
          onHide={this.hidePayments}
          onRequestClose={() => this.setState({ ...this.state, ...{ paymentsVisible: false } })}
          style={{ padding: 15 }}
        >
          <Subtitle title={"Zahlungsart auswählen und Bestellung abschließen"} containerStyle={{ paddingLeft: 0, marginBottom: 10 }} />
          <Divider style={{ marginBottom: 10 }} />
          <div>
            {/* Order Übersicht */}
            <div style={{display: "flex", flexFlow: "row", justifyContent: "space-between", alignItems: "center"}}>
              <Text> Bitte kontrollieren Sie Ihre Bestellung. </Text>
              <Button
                onClick={() => {
                  this.setState({ ...this.state, ...{ showOrderCheckUp: !this.state.showOrderCheckUp } });
                }}
                buttonType="icon"
                aria-label="Expand"
                theme="clear"
              >
                <IconRotator rotated={!this.state.showOrderCheckUp}>
                  <KeyboardArrowDownSVGIcon />
                </IconRotator>
              </Button>
            </div>
            <div style={{ display: this.state.showOrderCheckUp ?"" : "none", paddingLeft: 20, paddingRight: 10, maxHeight: "30vh", overflowY: "auto", marginTop: 10, marginBottom: 10 }}>
              {this.state.cart.order.map(x =>
                x.dishes.map(d =>
                  <div style={{ marginBottom: 15 }}>
                    <div style={{ display: "flex", flexFlow: "row", justifyContent: "space-between" }}>
                      <Text type="body-2">{`Nr. ${d.nr} ${d.name} / ${d.selection}`}</Text>
                      <Text type="body-2">{`${parseFloat(d.price).toFixed(2)} €`}</Text>
                    </div>
                    <div style={{ paddingLeft: 10 }}>
                      {d.notice !== "" && <Text style={{ fontSize: "0.9rem" }}>{`Anmerkung: ${d.notice}`}</Text>}
                      {d.minus && d.minus.map(m => <Text style={{ fontSize: "0.9rem !important" }}>{`- ${m.name}`}</Text>)}
                      {d.extras && d.extras.map(m => <Text style={{ fontSize: "0.9rem !important" }}>{`+ ${m.name}`}</Text>)}
                    </div>
                  </div>
                )
              )}

            </div>
            <Text style={{ marginTop: 15, marginBottom: 15 }}><b>{`Gesamtpreis: ${parseFloat(this.state.cart.orderPrice).toFixed(2)} €`}</b></Text>
            <div>
              <Text>Name: {this.state.customer.name}</Text>
              {this.state.customer.address !== "" && <Text>Adresse: {this.state.customer.address}</Text>}
              {this.state.customer.email !== "" && <Text>Email: {this.state.customer.email}</Text>}
              {this.state.customer.tel !== "" && <Text>Telefonnummer: {this.state.customer.tel}</Text>}
            </div>

          </div>

          <Divider style={{ marginBottom: 10 }} />
          <Button
            themeType="contained"
            disabled={this.state.cart.orderPrice < config.minPrice && this.state.delivery}
            onClick={() => this.send({})}
            style={{ width: "100%", marginBottom: 11 }}
          >
            Bar bezahlen.
          </Button>

          <div style={{ marginTop: 10, marginBottom: 10 }}>...oder bequem und einfach direkt online bezahlen.</div>

          {/* <div>
            <Subtitle title={"Bald auch direkt über Paypal bezahlen."} textStyle={{whiteSpace: "pre-wrap"}}/>
          </div> */}

          <PaypalButton
            confirmOrder={this.confirmOrder}
            send={this.send}
            customer={this.state.customer}
            cart={this.state.cart}
            validateUserForm={this.validateUserForm}
            disabled={this.state.cart.orderPrice < config.minPrice && this.state.delivery}
          />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              theme="error"
              themeType="contained"
              onClick={this.hidePayments}
              style={{ width: "90%", marginBottom: 10, marginTop: 10 }}
            >
              Abbrechen
            </Button>
          </div>

        </Dialog>
      </div>
    );
  }
}

export default withRouter(Sidebar);

const styles = {};
