import HttpService from './HttpService';
const config = require("../config/Config");


export default class DishService {

    static baseURL() {return `${config.ip}/items` }

    static getDishes(){
       return new Promise((resolve, reject) => {
           HttpService.get(`${DishService.baseURL()}/getitemsgrouped`, function(data) {
               resolve(data);
           }, function(textStatus) {
               reject(textStatus);
           });
       });
    }

    static getCategories(){
        return new Promise((resolve, reject) => {
            HttpService.get(`${DishService.baseURL()}/getcategories`, function(data) {
                // data = data.map(x => x._id)
                resolve(data);
            }, function(textStatus) {
                reject(textStatus);
            });
        });
     }

     static getExtras(){
        return new Promise((resolve, reject) => {
            HttpService.get(`${DishService.baseURL()}/getingredients`, function(data) {
                resolve(data);
            }, function(textStatus) {
                reject(textStatus);
            });
        });
     }
}