import React from "react";

import "../style/HomeView.css";


import Responsive from "react-responsive";
import { isMobile } from "react-device-detect";


const config = require("../config/Config");

export class LoginView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(props) {
  }


  render() {
    return (
      <div className={"flexBoxColumn"} style={{ height: "100%" }}>
        
      </div>
    );
  }
}

const styles = {
};
